import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Container, Row, Col } from "trainfes-components-library";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ContentCopy from '@mui/icons-material/ContentCopy';
import FileCopy from '@mui/icons-material/FileCopy';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import * as S from "./sessions.styles";
import * as P from "../Protocolos/protocolo.styles";
import {
  ArrowPatientTableIcon,
} from "../../../ClinicalRecordsPage/components/RecordTable/icons"
import Expand from "../../../../components/expand";

export const Card = ({
  e,
  handleMenuEdit,
  handleMenuDelete,
  handleMenuClone,
  handleCloneToOtherProtocol,
  handleToggle,
  currentSessionId,
}) => {
  const { t } = useTranslation("protocols");

  const [showBody, setShowBody] = useState(currentSessionId === e._id);

  const handleClic = () => {
    if (currentSessionId !== e._id) handleToggle(e);
    setShowBody((prev) => !prev);
  };

  useEffect(() => {
    if (currentSessionId === e._id) {
      setShowBody(true);
    } else {
      setShowBody(false);
    }
  }, [currentSessionId]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (callBack) => {
    return () => {
      handleClose();
      callBack();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <P.CardContainer active={currentSessionId === e._id}>
      <P.CardHeader onClick={handleClic}>
        <div className="header--info">
          <p className="_title">{e.name}</p>
          <p className="_sub-title">{e.fullaname}</p>
        </div>

        <div className={`_icon ${showBody ? "_active" : ""}`}>
          <ArrowPatientTableIcon />
        </div>
      </P.CardHeader>

      {showBody && (
        <P.CardBody>
          <Container>
            <Row>
              <Col xs={12} className="padding-0">
                <P.DescriptionTitle>{t("desc")}</P.DescriptionTitle>
                <hr className="my-1"></hr>
              </Col>

              <Col xs={11} className="padding-0">
                <S.Description
                  dangerouslySetInnerHTML={{
                    __html: e.description,
                  }}
                ></S.Description>
              </Col>
              <Col xs={1}>
                <IconButton
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClickListItem}
                  aria-label="Example">
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={handleMenuItemClick(handleMenuEdit)}>
                    <ListItemIcon>
                      <Edit fontSize="small" />
                    </ListItemIcon>
                    {t("formProtocol.edit")}
                  </MenuItem>
                  <MenuItem onClick={handleMenuItemClick(handleMenuClone)}>
                    <ListItemIcon>
                      <ContentCopy fontSize="small" />
                    </ListItemIcon>
                    {t("formProtocol.clone")}
                  </MenuItem>
                  <MenuItem onClick={handleMenuItemClick(handleCloneToOtherProtocol)}>
                    <ListItemIcon>
                      <FileCopy fontSize="small" />
                    </ListItemIcon>
                    {t("formProtocol.clone_to_other_protocol")}
                  </MenuItem>
                  <MenuItem onClick={handleMenuItemClick(handleMenuDelete)}>
                    <ListItemIcon>
                      <Delete fontSize="small" />
                    </ListItemIcon>
                    {t("formProtocol.delete")}
                  </MenuItem>
                </Menu>
              </Col>
            </Row>
            <Row>
              <Expand titulo={t("session.files")}>
                <ul className="files-session">
                  {e.files.map((f, i) => {
                    return <li key={i}>{f.name}</li>;
                  })}
                </ul>
              </Expand>
            </Row>
          </Container>
        </P.CardBody>
      )}
    </P.CardContainer>
  );
};
