import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import dayjs from "dayjs";
import {
  Unstable_Grid2 as Grid,
  Box,
  Divider,
  Typography,
  Button,
  Pagination,
  TextField,
  InputAdornment,
  ThemeProvider,
  Tabs,
  Tab,
  CircularProgress
} from "@mui/material";
import {
  ErrorOutline,
  CheckCircleOutline,
  Search,
  Add
} from "@mui/icons-material"
import { getUserId } from "../../lib/router";
import LoadingDialog from "../../components/LoadingDialog";
import GenericDialog from "../../components/GenericDialog";
import FullScreenModal from "../../components/FullScreenModal";
import EvaluationResultModal from "./components/EvaluationResultModal";
import PatientList from "./components/PatientList";
import theme from "../../styles/main.styles";
import HistoryTab from "./tabs/HistoryTab";
import ChartsEvolutionTab from "./tabs/ChartEvolutionTab";
import { useCreateEvaluation } from "../../queries/evaluation";
import { usePatientList } from "../../queries/patient";
import CreateOrEditPatientDialog from "./components/CreateOrEditPatientDialog";
import RecordsTab from "./tabs/RecordsTab";
import EvaluationsTab from "./tabs/EvaluationsTab";

// TabPanel component for rendering tab content
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`patient-evaluation-tabpanel-${index}`}
      aria-labelledby={`patient-evaluation-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const PatientEvaluation = () => {
  const { t } = useTranslation("patient_evaluation");
  const createEvaluation = useCreateEvaluation();

  // Patient list state
  const [search, setSearch] = useState("");
  const [selectedPatient, setSelectedPatient] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isEditing, setIsEditing] = useState(false);

  const {
    data: patientData,
    isLoading: loadingPatients,
    error: patientError
  } = usePatientList(currentPage, search);

  // Add modal state
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [patientDialogOpen, setPatientDialogOpen] = useState(false);

  const [evaluationTemplateId, setEvaluationTemplateId] = useState(null);
  let sections = useRef([]);
  const [validSections, setValidSection] = useState(false);
  let evaluatedAt = useRef(dayjs());

  // Feedback state
  const [errorDialog, setErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState(t("patient.error"));
  const [successDialog, setSuccessDialog] = useState(false);

  /* useMemo only triggers upon rerenders, so to avoid that we manually handle a validity check */
  const verifySectionValidity = () => {
    return setValidSection(sections.current.every(section => section.valid) ?? false);
  }

  // Tab state
  const [activeTab, setActiveTab] = useState(0);

  const hasMountedRef = useRef(false);

  const newPatient = () => {
    setIsEditing(false);
    setPatientDialogOpen(true);
  }

  const editPatient = () => {
    setIsEditing(true);
    setPatientDialogOpen(true);
  }

  // Functions for add modal
  const openAddDialog = () => {
    setAddDialogOpen(true);
  };

  const closeAddDialog = () => {
    setAddDialogOpen(false);
  };

  const initializeSections = (initialSections) => {
    sections.current = initialSections;
    verifySectionValidity();
  }

  const updateSections = (values, sectionIndex, blockIndex, validBlock, blockType) => {
    const newSections = [...sections.current];
    if (blockType === "DATA") {
      newSections[sectionIndex].blocks[blockIndex].params = values.map((param) => ({
        ...param,
        value: param.value === "" ? param.value : + param.value,
      }));
    } else {
      newSections[sectionIndex].blocks[blockIndex].params = values;
    }
    newSections[sectionIndex].blocks[blockIndex].params = values;
    newSections[sectionIndex].blocks[blockIndex].valid = validBlock;
    newSections[sectionIndex].valid = newSections[sectionIndex].blocks.every((block) => block.valid);
    sections.current = newSections;
    verifySectionValidity();
  };

  const updateEvaluatedAt = (newDate) => {
    evaluatedAt.current = newDate;
  }

  const saveResults = async () => {
    try {
      await createEvaluation.mutateAsync({
        evaluationTemplateId,
        sections: sections.current,
        therapistId: getUserId().userId,
        patientId: patientData?.patients[selectedPatient]._id,
        evaluatedAt: evaluatedAt.current.format("YYYY-MM-DD"),
      });
      setSuccessDialog(true);
      closeAddDialog();
    } catch (error) {
      setErrorMessage(t("results.error"));
      setErrorDialog(true);
    }
  };

  const handlePatientSelected = (index) => {
    setSelectedPatient(index);
  };

  // Search handling
  const debouncedSearch = debounce((value) => {
    setSearch(value);
    setCurrentPage(1); // Reset to first page on search
    setSelectedPatient(0); // Reset selected patient
  }, 500);

  useEffect(() => {
    if (!hasMountedRef.current) {
      hasMountedRef.current = true;
      return;
    }
    return () => {
      debouncedSearch.cancel();
    };
  }, []);

  const handleTabChange = (_, newValue) => {
    setActiveTab(newValue);
  };

  // Show error dialog if patient fetch fails
  useEffect(() => {
    if (patientError) {
      setErrorMessage(t("patient.error"));
      setErrorDialog(true);
    }
  }, [patientError]);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container padding={2} spacing={1}>
          {/* Patient list section */}
          <Grid container md={3} xs={12} direction="column" spacing={1}>
            <Grid container columnSpacing={1} sx={{ justifyContent: "space-between", alignItems: "center" }}>
              <Grid xs={12} md={4}>
                <Typography variant="h5" gutterBottom>
                  {t("patient.title")}
                </Typography>
              </Grid>
              <Grid xs={10} md={6}>
                <TextField
                  variant="outlined"
                  placeholder={t("patient.search")}
                  onChange={(e) => debouncedSearch(e.target.value)}
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    )
                  }}
                  sx={{ backgroundColor: "white" }}
                />
              </Grid>
              <Grid xs={2}>
                <Button
                  variant="contained"
                  sx={{ justifyContent: "center", alignItems: "center" }}
                  onClick={() => newPatient()}
                >
                  <Add />
                </Button>
              </Grid>
            </Grid>
            <Grid>
              <Divider />
            </Grid>
            <Grid container direction="column" spacing={1} sx={{ maxHeight: "70vh", overflow: "auto" }}>
              <PatientList
                patients={patientData?.patients || []}
                selectedPatient={selectedPatient}
                updateSelectedPatient={handlePatientSelected}
                loading={loadingPatients}
                editPatient={() => editPatient()}
              />
            </Grid>
            <Grid
              container
              paddingY={2}
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Grid>
                <Pagination
                  disabled={loadingPatients}
                  count={patientData?.totalPages || 1}
                  page={currentPage}
                  onChange={(_, page) => setCurrentPage(page)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container md={9} xs={12} direction="column" paddingX={4}>
            <Box sx={{ width: '100%' }}>
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  aria-label="patient evaluation tabs"
                  sx={{
                    "& .MuiTabs-fixed": {
                      backgroundColor: 'inherit !important',
                      borderBottom: 1,
                      borderColor: 'divider'
                    },
                  }}
                >
                  <Tab label={t("progress.tab")} id="patient-evaluation-tab-0" aria-controls="patient-evaluation-tabpanel-0" />
                  <Tab label={t("history.tab")} id="patient-evaluation-tab-1" aria-controls="patient-evaluation-tabpanel-1" />
                  <Tab label={t("evolution.tab")} id="patient-evaluation-tab-2" aria-controls="patient-evaluation-tabpanel-2" />
                  <Tab label={t("records.tab")} id="patient-evaluation-tab-3" aria-controls="patient-evaluation-tabpanel-3" />
                </Tabs>

                <Button
                  disabled={!patientData?.patients.length || loadingPatients || createEvaluation.isLoading}
                  variant="contained"
                  startIcon={<Add />}
                  onClick={openAddDialog}
                >
                  {t("history.add")}
                </Button>
              </Box>
              {loadingPatients ? (
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: '400px'
                }}>
                  <CircularProgress />
                </Box>
              ) : !patientData?.patients.length ? (
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: '400px'
                }}>
                  <Typography variant="body1" color="text.secondary">
                    {t("patient.no_patients")}
                  </Typography>
                </Box>
              ) : (
                <>
                  <TabPanel value={activeTab} index={0}>
                    <ChartsEvolutionTab patientId={patientData?.patients[selectedPatient]._id} />
                  </TabPanel>

                  <TabPanel value={activeTab} index={1}>
                    <HistoryTab
                      patient={patientData?.patients[selectedPatient]}
                    />
                  </TabPanel>

                  <TabPanel value={activeTab} index={2}>
                    <EvaluationsTab idActual={patientData?.patients[selectedPatient]._id} />
                  </TabPanel>

                  <TabPanel value={activeTab} index={3}>
                    <RecordsTab idActual={patientData?.patients[selectedPatient]._id} />
                  </TabPanel>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* Add modal */}
      {patientData?.patients.length > 0 && (
        <FullScreenModal
          open={addDialogOpen}
          handleClose={closeAddDialog}
          title={t("evaluation.title")}
          submitButton={t("evaluation.save")}
          onSubmit={saveResults}
          canSubmit={!createEvaluation.isLoading}
          saveDialog={true}
          exitDialog={true}
          validData={validSections}
          Content={
            <EvaluationResultModal
              patient={patientData.patients[selectedPatient]}
              updateTemplateId={setEvaluationTemplateId}
              initializeSections={initializeSections}
              updateSections={updateSections}
              updateEvaluatedAt={updateEvaluatedAt}
            />
          }
        />
      )}

      {/* Feedback dialogs */}
      <LoadingDialog
        open={createEvaluation.isLoading}
        message={t("results.loading")}
      />

      <GenericDialog
        open={successDialog}
        handleClose={() => setSuccessDialog(false)}
        icon={<CheckCircleOutline />}
        message={t("results.success")}
      />

      <GenericDialog
        open={errorDialog}
        handleClose={() => setErrorDialog(false)}
        icon={<ErrorOutline />}
        message={errorMessage}
      />
      {/* We only render this dialog when needed to ensure data is displayed properly */}
      {patientDialogOpen && (<CreateOrEditPatientDialog
        page={currentPage}
        search={search}
        dialogOpen={patientDialogOpen}
        closeDialog={() => setPatientDialogOpen(false)}
        existingData={isEditing && patientData?.patients[selectedPatient]}
      />)}
    </ThemeProvider>
  )
}

export default PatientEvaluation;