import React, { useState } from "react"
import {
  Unstable_Grid2 as Grid,
  Checkbox,
  FormControlLabel
} from "@mui/material";

const ResultTemplateMultiple = ({
  block,
  sectionIndex,
  blockIndex,
  updateParams,
  blockResult = null
}) => {
  const getInitialState = () => {
    return blockResult.params.map((item) => item.value);
  }

  const [result, setResult] = useState(
    blockResult ? getInitialState() : Array(block.params.length).fill(false));

  const updateResult = (value, index) => {
    const resultCopy = [...result];
    resultCopy[index] = value;
    const newResults = block?.params.map((param, idx) => {
      return {
        _id: param._id,
        value: resultCopy[idx]
      }
    });
    updateParams(
      newResults,
      sectionIndex,
      blockIndex,
      !block.required || resultCopy.some((item) => item)
    );
    setResult(resultCopy);
  }

  return (
    <Grid container direction="column" paddingTop={1}>
      {block.params.map((param, index) => (
        <FormControlLabel
          key={index}
          label={param.text}
          control={<Checkbox
            disabled={blockResult}
            checked={result[index]}
            onChange={(e) => updateResult(e.target.checked, index)}
          />}
        />
      ))}
    </Grid>
  )
}

export default ResultTemplateMultiple;