import React from "react";
import { useTranslation } from "react-i18next";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  CircularProgress,
  Box
} from "@mui/material";
import {
  VisibilityOutlined,
  Functions
} from "@mui/icons-material"

const EvaluationList = ({ evaluations, loading, chooseEvaluation }) => {
  const { t } = useTranslation("evaluations_new");

  const tableHeaders = [
    t("table.name"),
    t("table.tags"),
    t("table.creator"),
    t("table.date"),
    t("table.preview"),
    t("table.calculation")
  ]

  const formatDate = (date) => {
    if (!date) {
      return null;
    }
    return date.split("T")[0]
  }

  const formatName = (therapist) => {
    return `${therapist.name} ${therapist.lastname}`
  }

  return (
    <TableContainer sx={{ maxHeight: "70vh" }}>
      <Table>
        <TableHead>
          <TableRow>
            {tableHeaders.map((header, index) => (
              <TableCell
                key={index}
                sx={{ textAlign: "left !important", position: "sticky", top: 0, zIndex: 2 }}
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {loading ?
          <TableBody>
            <TableRow>
              <TableCell colSpan={tableHeaders.length} height={200}>
                <Box>
                  <CircularProgress />
                </Box>
                <Box >
                  {t("loading")}
                </Box>
              </TableCell>
            </TableRow>
          </TableBody> : <TableBody>
            {evaluations.map((evaluation, idx) => (
              <TableRow key={evaluation._id}>
                <TableCell sx={{ textAlign: "left !important" }}>
                  {evaluation.sections[0].title}
                </TableCell>
                <TableCell sx={{ textAlign: "left !important" }}>
                  {/* TODO: Tags section */}
                </TableCell>
                <TableCell sx={{ textAlign: "left !important" }}>
                  {formatName(evaluation.therapist)}
                </TableCell>
                <TableCell sx={{ textAlign: "left !important" }}>
                  {formatDate(evaluation.createdAt)}
                </TableCell>
                <TableCell sx={{ textAlign: "left !important" }}>
                  <IconButton onClick={() => chooseEvaluation(idx)}>
                    <VisibilityOutlined />
                  </IconButton>
                </TableCell>
                <TableCell sx={{ textAlign: "left !important" }}>
                  <IconButton disabled>
                    <Functions />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>}
      </Table>
    </TableContainer>
  )
}

export default EvaluationList;