import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Pagination,
  Unstable_Grid2 as Grid
} from "@mui/material";
import ResultList from "./ResultList";
import GenericDialog from "../../../../components/GenericDialog";
import { ErrorOutline } from "@mui/icons-material";
import { usePatientEvaluations } from "../../../../queries/evaluation";

const HistoryTab = ({ patient }) => {
  const { t } = useTranslation("patient_evaluation");
  const [page, setPage] = useState(1);

  const { 
    data,
    isLoading: loadingResults,
    error 
  } = usePatientEvaluations(patient?._id, page);

  const handlePageChange = (_, newPage) => {
    setPage(newPage);
  };

  return (
    <Grid container direction="column" spacing={1}>
      <Grid sx={{ justifyContent: "space-between" }}>
        {!loadingResults ? <Grid>
          <Typography variant="h5" marginY={2}>
            {data?.total} {t("title").toLowerCase()}
          </Typography>
        </Grid> : null}
        <Grid>
          <ResultList
            results={data?.evaluations || []}
            loading={loadingResults}
            patient={patient}
          />
        </Grid>
        <Grid
          container
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Grid>
            <Pagination
              disabled={loadingResults}
              count={data?.pages || 1}
              page={page}
              onChange={handlePageChange}
            />
          </Grid>
        </Grid>
      </Grid>

      {error && (
        <GenericDialog
          open={!!error}
          handleClose={() => {}}
          icon={<ErrorOutline />}
          message={t("patient.errorResults")}
        />
      )}
    </Grid>
  );
};

export default HistoryTab; 