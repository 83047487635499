import React, { useState } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Box,
  CircularProgress,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Menu,
  MenuItem
} from '@mui/material';

import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  MoreHoriz as MoreIcon
} from '@mui/icons-material';
import ChartFormModal from './ChartFormModal';
import ChartRenderer from './ChartRenderer';
import { transformSeriesData, calculateYAxisRange, getAllUniqueDates } from '../../utils/chartUtils';
import { useFilteredChartData, usePatientCharts, useDeleteChart } from '../../../../queries/charts';
import { useTranslation } from 'react-i18next';
import { standardizeSeriesDataUnits } from '../../utils/chartUtils';

const DeleteConfirmationDialog = ({ open, onClose, onConfirm }) => {
  const { t } = useTranslation("patient_evaluation");
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t("progress.delete_chart.title")}</DialogTitle>
      <DialogContent>
        {t("progress.delete_chart.description")}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>{t("progress.cancel")}</Button>
        <Button variant="contained" onClick={onConfirm} color="error">
          {t("progress.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ChartContainer = ({ title, description, children, onEdit, onDelete }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const menuOpen = Boolean(menuAnchorEl);
  const { t } = useTranslation("patient_evaluation");

  const handleOpenMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleEdit = () => {
    handleCloseMenu();
    onEdit();
  };

  const handleDelete = () => {
    handleCloseMenu();
    onDelete();
  };

  return (
    <Paper elevation={3} sx={{ p: 2, height: '100%', position: 'relative' }}>
      <Box sx={{
        position: 'absolute',
        top: 8,
        right: 8,
        zIndex: 1,
        display: 'flex',
        gap: 1
      }}>
        <IconButton onClick={handleOpenMenu} color="primary">
          <MoreIcon />
        </IconButton>
        <Menu
          anchorEl={menuAnchorEl}
          open={menuOpen}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={handleEdit}><EditIcon /> {t("progress.edit")}</MenuItem>
          <MenuItem onClick={handleDelete} color="error"><DeleteIcon /> {t("progress.delete")}</MenuItem>
        </Menu>
      </Box>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      {description && (
        <Typography variant="body2" color="text.secondary" gutterBottom>
          {description}
        </Typography>
      )}
      <Box sx={{ height: 300 }}>
        {children}
      </Box>
    </Paper>
  );
};

const Chart = ({ graphId, trackedItems, xAxes, yAxes }) => {
  const { data: chartData, isLoading } = useFilteredChartData(graphId);

  if (isLoading || !chartData) {
    return <CircularProgress />;
  }

  // Transform the data using shared utilities
  const chartSeries = chartData.map(seriesData => {
    const trackedItem = trackedItems.find(item => item._id === seriesData.trackedItemId);
    return {
      ...seriesData,
      type: trackedItem?.seriesType,
      label: trackedItem?.label,
      color: trackedItem?.color,
      aggregations: trackedItem?.aggregations || { average: true }, // Default to showing average only
      unit: seriesData?.unit,
      dimension: seriesData?.dimension
    }
  });
  const standardizedSeries = standardizeSeriesDataUnits(chartSeries);
  const transformedSeries = transformSeriesData(
    standardizedSeries,
    xAxes?.[0]?.scaleType,
  );

  const yAxisRange = calculateYAxisRange(transformedSeries);
  const allDates = getAllUniqueDates(chartData);

  // Configure x-axis
  const xAxisConfig = {
    ...xAxes?.[0],
    ...(xAxes?.[0]?.scaleType === 'time' && {
      data: allDates.map(date => new Date(date)),
      min: new Date(allDates[0]),
      max: new Date(allDates[allDates.length - 1])
    }),
    ...(xAxes?.[0]?.scaleType === 'band' && {
      data: allDates
    }),
    ...(xAxes?.[0]?.scaleType === 'point' && {
      data: allDates
    }),
    ...(xAxes?.[0]?.scaleType === 'linear' && {
      data: allDates.map((_, index) => index + 1)
    }),
  };

  // Configure y-axis with calculated min/max
  const yAxesConfigs = yAxes?.map(axis => ({
    ...axis,
    min: yAxisRange.min,
    max: yAxisRange.max,
  }));

  return (
    <ChartRenderer
      series={transformedSeries}
      xAxes={[xAxisConfig]}
      yAxes={yAxesConfigs}
      height={300}
    />
  );
};

const ChartsEvolutionTab = ({ patientId }) => {
  const { t } = useTranslation("patient_evaluation");
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [editingChart, setEditingChart] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState({ open: false, chartId: null });

  const { mutateAsync: deleteChart } = useDeleteChart(patientId);

  const { data: patientCharts, isLoading } = usePatientCharts(patientId);

  const handleCreateChart = () => {
    setEditingChart(null);
    setIsCreateModalOpen(true);
  };

  const handleEditChart = (chart) => {
    setEditingChart(chart);
    setIsCreateModalOpen(true);
  };

  const handleDeleteChart = (chartId) => {
    setDeleteConfirmation({ open: true, chartId });
  };

  const handleConfirmDelete = async () => {
    if (deleteConfirmation.chartId) {
      await deleteChart(deleteConfirmation);
    }
    setDeleteConfirmation({ open: false, chartId: null });
  };

  const handleCloseModal = () => {
    setIsCreateModalOpen(false);
    setEditingChart(null);
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box>
      {/* Header with create button */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          m: 2
        }}
      >
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleCreateChart}
        >
          {t("progress.create_chart")}
        </Button>
      </Box>

      {/* Charts grid */}
      <Grid container spacing={2} sx={{ maxHeight: '70vh', overflow: 'auto', mt: 0 }}>
        {patientCharts?.patientCharts.map(graph => (
          <Grid
            item
            xs={12}
            md={patientCharts.patientCharts.length === 1 ? 12 : 6}
            key={graph._id}
          >
            <ChartContainer
              title={graph.name}
              description={graph.description}
              onEdit={() => handleEditChart(graph)}
              onDelete={() => handleDeleteChart(graph._id)}
            >
              <Chart
                graphId={graph._id}
                trackedItems={graph.trackedItems}
                xAxes={graph.xAxes}
                yAxes={graph.yAxes}
              />
            </ChartContainer>
          </Grid>
        ))}
      </Grid>

      <ChartFormModal
        open={isCreateModalOpen}
        onClose={handleCloseModal}
        chart={editingChart}
        patientId={patientId}
      />

      <DeleteConfirmationDialog
        open={deleteConfirmation.open}
        onClose={() => setDeleteConfirmation({ open: false, chartId: null })}
        onConfirm={handleConfirmDelete}
      />
    </Box>
  );
};

export default ChartsEvolutionTab;