import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Select, MenuItem, InputLabel } from '@mui/material';
import { DatePickerComponent } from "../../../../components/DatePickerv2";
import { handleDatePickerChangeEvol } from "../../clinicalRecords.helpers";

import { useEffect } from "react";
import { SidebarContent } from "../../../../components/CardLayoutPlatform/styles";
import API from "../../../../services/api";

const api = new API();

export const FilterSidebar = ({ setDateFiltered, handleCloseSidebar, setSpecialityFilter, onFilterChange }) => {
  const { t } = useTranslation("registers");

  // Store filter values in component state
  const [filterValues, setFilterValues] = useState({
    speciality: "",
    fromDate: null,
    toDate: null,
  });

  // -> Value para dropdown.
  const [allSpecialities, setAllSpecialities] = useState(null);
  const [speciality, setSpeciality] = useState(filterValues.speciality);

  // -> Values para calendarios.
  const [fromDate, setFromDate] = useState(filterValues.fromDate);
  const [toDate, setToDate] = useState(filterValues.toDate);

  // -> Validaciones para mínimo y máximo en calendario.
  const [minimumDate, setMinimumDate] = useState();
  const [maximumDate, setMaximumDate] = useState();

  useEffect(() => {
    setFromDate(filterValues.fromDate);
    setToDate(filterValues.toDate);
    setSpeciality(filterValues.speciality);
  }, [filterValues]);

  // Store initial filter values in component state
  const [initialFilterValues, setInitialFilterValues] = useState({
    speciality: "",
    fromDate: null,
    toDate: null,
  });

  const handleGetSpecialities = async (ac) => {
    const { data } = await api.getSpeciality({ signal: ac.signal });

    setAllSpecialities(
      data.map((item) => {
        return { label: item.name, value: item.name };
      })
    );
  };

  useEffect(() => {
    const ac = new AbortController();

    handleGetSpecialities(ac);
    return () => {
      setAllSpecialities(null);
      ac.abort();
    };
  }, []);

  const formatDateToISO = (dateObj) => {
    return `${dateObj.year}-${dateObj.month.toString().padStart(2, '0')}-${dateObj.day.toString().padStart(2, '0')}${dateObj === fromDate ? "T04:00:00.000Z" : "T23:59:59.999Z"}`;
  };

  const handleSubmit = () => {
    const newDateFilter = {
      from: fromDate ? formatDateToISO(fromDate) : null,
      to: toDate ? formatDateToISO(toDate) : null,
    };
    const newSpeciality = speciality ? speciality.value : "";
    setDateFiltered(newDateFilter);
    setSpecialityFilter(newSpeciality);
    onFilterChange(newSpeciality, newDateFilter);

    setFilterValues({
      speciality: newSpeciality,
      fromDate: fromDate,
      toDate: toDate,
    });

    setInitialFilterValues({
      speciality: newSpeciality,
      fromDate: fromDate,
      toDate: toDate,
    });

    handleCloseSidebar();
  };

  const handleClearFilter = () => {
    setFromDate(undefined);
    setToDate(undefined);
    setMinimumDate(undefined);
    setMaximumDate(undefined);
    setSpeciality({ label: "", value: "" });
    setSpecialityFilter("");

    setDateFiltered({
      from: null,
      to: null,
    });

    handleCloseSidebar();
    onFilterChange("", { from: null, to: null });

    // Reset filter values to initial filter values
    setFilterValues(initialFilterValues);
  };


  return (
    <SidebarContent>
      <div className="form">
        <InputLabel>{t("specialism")}</InputLabel>
        <Select
          fullWidth
          value={(speciality && speciality.value) || filterValues.speciality}
          onChange={(e) => {
            // Find the matching specialty object
            const selectedSpecialty = allSpecialities?.find(s => s.value === e.target.value) || { label: "", value: e.target.value };
            setSpeciality(selectedSpecialty);
          }}
          label={t("specialism")}
        >
          {allSpecialities?.map(specialty => (
            <MenuItem key={specialty.value} value={specialty.value}>
              {specialty.label}
            </MenuItem>
          ))}
        </Select>

        <p className="mrgn-top-30">{t("date_range")}</p>

        <label>{t("from")}</label>
        <DatePickerComponent
          height="36px"
          placeholder=""
          range={false}
          value={fromDate || filterValues.fromDate}
          onChange={(val) => handleDatePickerChangeEvol(val, setFromDate, setMinimumDate)}
          maximumDate={maximumDate}
        />

        <label>{t("to")}</label>
        <DatePickerComponent
          height="36px"
          placeholder=""
          range={false}
          value={toDate || filterValues.toDate}
          onChange={(val) => handleDatePickerChangeEvol(val, setToDate, setMaximumDate)}
          minimumDate={minimumDate ? minimumDate : undefined}
        />
      </div>

      <Button align="center" sx={{ mt: 2 }} variant="contained" onClick={handleSubmit}>{t("apply_filters")}</Button>
      <Button align="center" sx={{ mt: 2 }} variant="outlined" onClick={handleClearFilter}>{t("clear_filters")}</Button>
    </SidebarContent>
  );
};
