/**
 * Internal library to convert units of measurement.
 * 
 * @module units_converter
 * 
 * Current volume units:
 * - Liters
 * - Milliliters
 * - Centiliters
 */

/**
 * Volume conversion functions
 * @namespace volume
 */

/**
 * Convert liters to milliliters
 * @param {number} liters - The volume in liters
 * @returns {number} The volume in milliliters
 */
const litersToMilliliters = (liters) => {
  return liters * 1000;
};

/**
 * Convert milliliters to liters
 * @param {number} milliliters - The volume in milliliters
 * @returns {number} The volume in liters
 */
const millilitersToLiters = (milliliters) => {
  return milliliters / 1000;
};

/**
 * Convert liters to centiliters
 * @param {number} liters - The volume in liters
 * @returns {number} The volume in centiliters
 */
const litersToCentiliters = (liters) => {
  return liters * 100;
};

/**
 * Convert centiliters to liters
 * @param {number} centiliters - The volume in centiliters
 * @returns {number} The volume in liters
 */
const centilitersToLiters = (centiliters) => {
  return centiliters / 100;
};

/**
 * Convert milliliters to centiliters
 * @param {number} milliliters - The volume in milliliters
 * @returns {number} The volume in centiliters
 */
const millilitersToCentiliters = (milliliters) => {
  return milliliters / 10;
};

/**
 * Convert centiliters to milliliters
 * @param {number} centiliters - The volume in centiliters
 * @returns {number} The volume in milliliters
 */
const centilitersToMilliliters = (centiliters) => {
  return centiliters * 10;
};

/**
 * Volume conversion table
 * First key is the source unit, second key is the target unit
 */
const VOLUME_CONVERTION_TABLE = {
  l: {
    ml: litersToMilliliters,
    cl: litersToCentiliters,
  },
  ml: {
    l: millilitersToLiters,
    cl: millilitersToCentiliters,
  },
  cl: {
    l: centilitersToLiters,
    ml: centilitersToMilliliters,
  },
}

export {
  litersToMilliliters,
  millilitersToLiters,
  litersToCentiliters,
  centilitersToLiters,
  millilitersToCentiliters,
  centilitersToMilliliters,
  VOLUME_CONVERTION_TABLE,
};