import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
  Select,
  MenuItem,
  FormGroup,
  InputLabel,
} from '@mui/material';
import { useEvaluationTemplates } from '../../../../queries/evaluation_template';
import ChartPreview from './ChartPreview';
import EditableAxisLabel from './EditableAxisLabel';
import { styled } from '@mui/material/styles';
import {
  useCreateChart,
  useUpdateChart,
  useFilteredParameterTimeSeries
} from '../../../../queries/charts';
import { useTranslation } from 'react-i18next';
import { standardizeSeriesDataUnits } from '../../utils/chartUtils';
// Create the styled component
const SeriesConfigBox = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'seriesColor'
})(({ theme, seriesColor }) => ({
  flex: 1,
  backgroundColor: `${seriesColor}08`,
  padding: `${theme.spacing(2)} !important`,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${seriesColor}40`,
}));

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  paddingTop: "4px !important",
  paddingBottom: "4px !important"
}));

const ChartFormModal = ({ open, onClose, chart, patientId }) => {
  const { t } = useTranslation("patient_evaluation");
  const [selectedEvaluation, setSelectedEvaluation] = useState('');
  const [isLabelManuallyEdited, setIsLabelManuallyEdited] = useState(false);

  // First series states
  const [firstVariable, setFirstVariable] = useState('');
  const [firstChartType, setFirstChartType] = useState('line');
  const [firstColor, setFirstColor] = useState('#1976d2');
  const [firstLabel, setFirstLabel] = useState('');

  // Second series states
  const [secondVariable, setSecondVariable] = useState('');
  const [secondChartType, setSecondChartType] = useState('line');
  const [secondColor, setSecondColor] = useState('#fdad34');
  const [secondLabel, setSecondLabel] = useState('');

  // Common states
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [xAxisType, setXAxisType] = useState('time');
  const [yAxisType, setYAxisType] = useState('linear');
  const [xAxisLabel, setXAxisLabel] = useState('Date');
  const [yAxisLabel, setYAxisLabel] = useState('Value');

  // Add new state for aggregation methods
  const [firstSeriesAggregations, setFirstSeriesAggregations] = useState({
    average: true,
    min: false,
    max: false
  });

  const [secondSeriesAggregations, setSecondSeriesAggregations] = useState({
    average: true,
    min: false,
    max: false
  });

  // Fetch available evaluations templates
  const { data: evaluationsTemplates } = useEvaluationTemplates();

  // Get variables from the selected template
  const variables = useMemo(() => {
    if (!selectedEvaluation || !evaluationsTemplates?.templates) return [];

    const selectedTemplate = evaluationsTemplates.templates.find(
      template => template._id === selectedEvaluation
    );

    if (!selectedTemplate) return [];

    // Extract blocks from all sections and map them to variables format
    return selectedTemplate.sections.flatMap(section =>
      section.blocks.map(block => ({
        _id: block._id,
        sectionId: section._id,
        name: block.title,
        type: block.type,
        typeConfig: block.typeConfig
      }))
    );
  }, [selectedEvaluation, evaluationsTemplates]);

  // Get the selected variable's data using the existing hook
  const firstVariableInfo = variables.find(v => v._id === firstVariable);
  const secondVariableInfo = variables.find(v => v._id === secondVariable);
  const resetVariable = (variable) => {
    switch (variable) {
      case 'first':
        setFirstVariable('');
        setFirstChartType('line');
        setFirstColor('#1976d2');
        setFirstLabel('');
        setFirstSeriesAggregations({
          average: true,
          min: false,
          max: false
        });
        break;
      case 'second':
        setSecondVariable('');
        setSecondChartType('line');
        setSecondColor('#2196f3');
        setSecondLabel('');
        setSecondSeriesAggregations({
          average: true,
          min: false,
          max: false
        });
        break;
      default:
        break;
    }
  };

  const handleVariableChange = (variableId) => {
    // If variable is already selected as first variable, unselect it
    if (firstVariable === variableId) {
      // Move second variable to first position with its settings
      if (secondVariable) {
        setFirstVariable(secondVariable);
        handleChartTypeChange('first', secondChartType)
        setFirstColor(secondColor);
        setFirstLabel(secondLabel);
        setFirstSeriesAggregations(secondSeriesAggregations);

        // Clear second variable and its settings
        resetVariable('second');
      } else {
        // If no second variable, just clear first variable
        resetVariable('first');
      }
      return;
    }

    // If variable is already selected as second variable, unselect it
    if (secondVariable === variableId) {
      resetVariable('second');
      return;
    }

    // If no first variable is selected, set as first
    if (!firstVariable) {
      setFirstVariable(variableId);
      return;
    }

    // If no second variable is selected, set as second
    if (!secondVariable) {
      setSecondVariable(variableId);
      return;
    }
  };

  // Data queries
  const firstQuery = useFilteredParameterTimeSeries(
    patientId,
    selectedEvaluation,
    firstVariableInfo?.sectionId,
    firstVariable
  );

  const secondQuery = useFilteredParameterTimeSeries(
    patientId,
    selectedEvaluation,
    secondVariableInfo?.sectionId,
    secondVariable
  );

  // Initialize form when editing
  useEffect(() => {
    if (chart) {
      // Common settings
      setName(chart.name || '');
      setDescription(chart.description || '');
      setSelectedEvaluation(chart.trackedItems[0]?.parameterData?.evaluationTemplateId || '');
      setXAxisType(chart.xAxes?.[0]?.scaleType || 'time');
      setYAxisType(chart.yAxes?.[0]?.scaleType || 'linear');
      setXAxisLabel(chart.xAxes?.[0]?.label || 'Date');
      setYAxisLabel(chart.yAxes?.[0]?.label || 'Value');

      // First series settings
      setFirstVariable(chart.trackedItems[0]?._id || '');
      setFirstChartType(chart.trackedItems[0]?.seriesType || 'line');
      setFirstColor(chart.trackedItems[0]?.color || '#1976d2');
      setFirstLabel(chart.trackedItems[0]?.label || '');
      setIsLabelManuallyEdited(!!chart.trackedItems[0]?.label);
      setFirstSeriesAggregations(chart.trackedItems[0]?.aggregations || {
        average: true,
        min: false,
        max: false
      });

      // Initialize second series if it exists
      if (chart.trackedItems?.[1]) {
        setSecondVariable(chart.trackedItems[1]._id || '');
        setSecondChartType(chart.trackedItems[1].seriesType || 'line');
        setSecondColor(chart.trackedItems[1].color || '#2196f3');
        setSecondLabel(chart.trackedItems[1].label || '');
        setSecondSeriesAggregations(chart.trackedItems[1]?.aggregations || {
          average: true,
          min: false,
          max: false
        });
      }
    }
  }, [chart]);

  useEffect(() => {
    // Update series label when variable changes, unless it's been manually edited
    if (firstVariableInfo && !isLabelManuallyEdited) {
      setFirstLabel(firstVariableInfo.name);
    }
  }, [firstVariableInfo, isLabelManuallyEdited]);

  useEffect(() => {
    if (secondVariableInfo && !isLabelManuallyEdited) {
      setSecondLabel(secondVariableInfo.name);
    }
  }, [secondVariableInfo, isLabelManuallyEdited]);

  const availableYAxisTypes = useMemo(() => [
    { value: 'linear', label: 'Linear' },
    { value: 'sqrt', label: 'Square Root' }
  ], []);

  // Handle chart type change and update axis types accordingly
  const handleChartTypeChange = (seriesId, newType) => {
    if (seriesId === 'first') {
      // If the new combination would be invalid, adjust the x-axis type
      if (secondChartType && !isValidChartCombination(newType, secondChartType)) {
        if (newType === 'bar') {
          setXAxisType('band');
        } else {
          setXAxisType('time');
        }
      }
      setFirstChartType(newType);
    } else {
      // If the new combination would be invalid, adjust the x-axis type
      if (!isValidChartCombination(firstChartType, newType)) {
        if (newType === 'bar') {
          setXAxisType('band');
        } else {
          setXAxisType('time');
        }
      }
      setSecondChartType(newType);
    }
  };

  // Add this validation helper
  const isValidChartCombination = (type1, type2) => {
    // If there's only one series, it's always valid
    if (!type2) return true;

    // For combinations involving bar charts, x-axis must be 'band'
    if (type1 === 'bar' || type2 === 'bar') {
      return xAxisType === 'band';
    }

    // Line and scatter combinations are valid with compatible x-axis types
    const validLineScatterAxisTypes = ['point', 'linear', 'log', 'sqrt', 'time', 'utc'];
    if ((type1 === 'line' || type1 === 'scatter') &&
      (type2 === 'line' || type2 === 'scatter')) {
      return validLineScatterAxisTypes.includes(xAxisType);
    }

    return true;
  };

  // Add this to manage available axis types based on chart combinations
  const getAvailableXAxisTypes = useCallback(() => {
    if (firstChartType === 'bar' || secondChartType === 'bar') {
      return [{ value: 'band', label: 'Band' }];
    }

    if (firstChartType === 'scatter' || secondChartType === 'scatter') {
      return [
        { value: 'time', label: 'Time' },
        { value: 'linear', label: 'Linear' },
        { value: 'point', label: 'Categories' },
      ];
    }

    return [
      { value: 'time', label: 'Time' },
      { value: 'linear', label: 'Linear' },
      { value: 'point', label: 'Categories' },
    ];
  }, [firstChartType, secondChartType]);

  const handleClose = () => {
    // Reset form
    setName('');
    setDescription('');
    setSelectedEvaluation('');
    setXAxisType('time');
    setYAxisType('linear');
    setXAxisLabel('Date');
    setYAxisLabel('Value');
    // Reset variables
    resetVariable('first');
    resetVariable('second');
    // Reset label edit state
    setIsLabelManuallyEdited(false);

    onClose();
  };

  const handleSeriesChange = (seriesId, updates) => {
    switch (seriesId) {
      case firstVariable:
        if (updates.color) setFirstColor(updates.color);
        if (updates.label) setFirstLabel(updates.label);
        break;
      case secondVariable:
        if (updates.color) setSecondColor(updates.color);
        if (updates.label) setSecondLabel(updates.label);
        break;
      default:
        break;
    }
  };

  // Handler for aggregation changes
  const handleAggregationChange = (seriesId, method) => {
    if (seriesId === 'first') {
      setFirstSeriesAggregations(prev => ({
        ...prev,
        [method]: !prev[method]
      }));
    } else {
      setSecondSeriesAggregations(prev => ({
        ...prev,
        [method]: !prev[method]
      }));
    }
  };

  // Add this helper function
  const isLastCheckedAggregation = (aggregations, currentMethod) => {
    // If this is the only checked aggregation, don't allow unchecking
    return aggregations[currentMethod] &&
      Object.entries(aggregations).filter(([key, value]) => value).length === 1;
  };

  // Replace the existing mutations with the new hooks
  const createChartMutation = useCreateChart(patientId, {
    onSuccess: handleClose
  });

  const updateChartMutation = useUpdateChart(patientId, {
    onSuccess: handleClose
  });

  // Unselect variables when the evaluation changes
  const handleEvaluationChange = (evaluationId) => {
    if (evaluationId !== selectedEvaluation) {
      setSelectedEvaluation(evaluationId);
      resetVariable('first');
      resetVariable('second');
    }
  };

  const handleSave = () => {
    const chartData = {
      name,
      description,
      patientId,
      trackedItems: [
        {
          _id: firstVariable,
          type: "parameter",
          seriesType: firstChartType,
          yAxisId: 'left',
          color: firstColor,
          label: firstLabel,
          aggregations: firstSeriesAggregations,
          parameterData: {
            evaluationTemplateId: selectedEvaluation,
            sectionId: firstVariableInfo?.sectionId,
            blockId: firstVariableInfo?._id,
          }
        },
        // Add second series if it exists
        secondVariable && {
          _id: secondVariable,
          type: "parameter",
          seriesType: secondChartType,
          yAxisId: 'left',
          color: secondColor,
          label: secondLabel,
          aggregations: secondSeriesAggregations,
          parameterData: {
            evaluationTemplateId: selectedEvaluation,
            sectionId: secondVariableInfo?.sectionId,
            blockId: secondVariableInfo?._id,
          }
        }
      ].filter(Boolean), // Remove undefined items
      xAxes: [{
        id: 'x-axis',
        scaleType: xAxisType,
        label: xAxisLabel
      }],
      yAxes: [{
        id: 'left',
        scaleType: yAxisType,
        label: yAxisLabel
      }]
    };

    if (chart) {
      updateChartMutation.mutate({ ...chartData, _id: chart._id });
    } else {
      createChartMutation.mutate(chartData);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        {chart ? t("progress.chart_form.edit_title") : t("progress.chart_form.new_title")}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}>
          <TextField
            label={t("progress.chart_form.name")}
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
          />
          <TextField
            label={t("progress.chart_form.description")}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
          />
          <FormControl fullWidth>
            <InputLabel>{t("progress.chart_form.evaluation")}</InputLabel>
            <Select
              value={selectedEvaluation}
              onChange={(e) => handleEvaluationChange(e.target.value)}
              label={t("progress.chart_form.select_evaluation")}
            >
              {evaluationsTemplates?.templates?.map(template => (
                <MenuItem key={template._id} value={template._id}>
                  {template.sections[0].title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {selectedEvaluation && variables.length > 0 && (
            <Box sx={{ mt: 1 }}>
              <Typography variant="subtitle2" sx={{ mt: 1 }}>
                {t("progress.chart_form.select_variables")}
              </Typography>
              <Box
                sx={{
                  maxHeight: '200px',
                  overflowY: 'auto',
                  border: 1,
                  borderColor: 'divider',
                  borderRadius: 1,
                  paddingLeft: "10px !important"
                }}
              >
                <FormGroup>
                  {variables.map(variable => {
                    const isFirstVariable = firstVariable === variable._id;
                    const isSecondVariable = secondVariable === variable._id;
                    const isDisabled = !['SINGLE', 'DATA'].includes(variable.type) ||
                      !!(firstVariable !== variable._id &&
                        secondVariable !== variable._id &&
                        firstVariable && secondVariable);

                    return (
                      <FormControlLabel
                        key={variable._id}
                        control={
                          <CustomCheckbox
                            disabled={isDisabled}
                            checked={isFirstVariable || isSecondVariable}
                            onChange={() => handleVariableChange(variable._id)}
                          />
                        }
                        label={
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography
                              sx={{
                                opacity: isDisabled ? 0.5 : 1,
                                color: isFirstVariable ? firstColor : 
                                       isSecondVariable ? secondColor : 
                                       'text.primary'
                              }}
                            >
                              {variable.name}
                            </Typography>
                            {(isFirstVariable || isSecondVariable) && (
                              <Typography
                                variant="caption"
                                sx={{
                                  color: isFirstVariable ? firstColor : secondColor,
                                  bgcolor: `${isFirstVariable ? firstColor : secondColor}15`,
                                  px: 1,
                                  py: 0.5,
                                  borderRadius: 1,
                                  fontWeight: 'medium'
                                }}
                              >
                                {isFirstVariable ? t("progress.chart_form.series_1") : t("progress.chart_form.series_2")}
                              </Typography>
                            )}
                          </Box>
                        }
                      />
                    );
                  })}
                </FormGroup>
              </Box>
            </Box>
          )}

          {/* Chart Type and Aggregation Configuration in Columns */}
          {(firstVariable || secondVariable) && (
            <Box sx={{ display: 'flex', gap: 4, mt: 2 }}>
              {/* First Series Column */}
              {firstVariable && (
                <SeriesConfigBox seriesColor={firstColor}>
                  <Typography variant="subtitle1" sx={{ mb: 2, color: firstColor }}>
                    {t("progress.chart_form.series_1_configuration")}
                  </Typography>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>{t("progress.chart_form.chart_type")}</InputLabel>
                    <Select
                      value={firstChartType}
                      onChange={(e) => handleChartTypeChange('first', e.target.value)}
                      label={t("progress.chart_form.chart_type")}
                    >
                      <MenuItem value="line">{t("progress.chart_form.line_chart")}</MenuItem>
                      <MenuItem value="scatter">{t("progress.chart_form.scatter_plot")}</MenuItem>
                      <MenuItem value="bar">{t("progress.chart_form.bar_chart")}</MenuItem>
                    </Select>
                  </FormControl>

                  {firstChartType !== 'scatter' && (
                    <>
                      <Typography variant="subtitle2" sx={{ mb: 1 }}>
                        {t("progress.chart_form.series_aggregations")}
                      </Typography>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <CustomCheckbox
                              checked={firstSeriesAggregations.average}
                              onChange={() => handleAggregationChange('first', 'average')}
                              disabled={isLastCheckedAggregation(firstSeriesAggregations, 'average')}
                            />
                          }
                          label={t("progress.chart_form.average")}
                        />
                        <FormControlLabel
                          control={
                            <CustomCheckbox
                              checked={firstSeriesAggregations.min}
                              onChange={() => handleAggregationChange('first', 'min')}
                              disabled={isLastCheckedAggregation(firstSeriesAggregations, 'min')}
                            />
                          }
                          label={t("progress.chart_form.minimum")}
                        />
                        <FormControlLabel
                          control={
                            <CustomCheckbox
                              checked={firstSeriesAggregations.max}
                              onChange={() => handleAggregationChange('first', 'max')}
                              disabled={isLastCheckedAggregation(firstSeriesAggregations, 'max')}
                            />
                          }
                          label={t("progress.chart_form.maximum")}
                        />
                      </FormGroup>
                    </>
                  )}
                </SeriesConfigBox>
              )}

              {/* Second Series Column */}
              {secondVariable && (
                <SeriesConfigBox seriesColor={secondColor}>
                  <Typography variant="subtitle1" sx={{ mb: 2, color: secondColor }}>
                    {t("progress.chart_form.series_2_configuration")}
                  </Typography>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>{t("progress.chart_form.chart_type")}</InputLabel>
                    <Select
                      value={secondChartType}
                      onChange={(e) => handleChartTypeChange('second', e.target.value)}
                      label={t("progress.chart_form.chart_type")}
                    >
                      <MenuItem value="line">{t("progress.chart_form.line_chart")}</MenuItem>
                      <MenuItem value="scatter">{t("progress.chart_form.scatter_plot")}</MenuItem>
                      <MenuItem value="bar" disabled={firstChartType !== 'bar'}>{t("progress.chart_form.bar_chart")}</MenuItem>
                    </Select>
                  </FormControl>

                  {secondChartType !== 'scatter' && (
                    <>
                      <Typography variant="subtitle2" sx={{ mb: 1 }}>
                        {t("progress.chart_form.series_aggregations")}
                      </Typography>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <CustomCheckbox
                              checked={secondSeriesAggregations.average}
                              onChange={() => handleAggregationChange('second', 'average')}
                              disabled={isLastCheckedAggregation(secondSeriesAggregations, 'average')}
                            />
                          }
                          label={t("progress.chart_form.average")}
                        />
                        <FormControlLabel
                          control={
                            <CustomCheckbox
                              checked={secondSeriesAggregations.min}
                              onChange={() => handleAggregationChange('second', 'min')}
                              disabled={isLastCheckedAggregation(secondSeriesAggregations, 'min')}
                            />
                          }
                          label={t("progress.chart_form.minimum")}
                        />
                        <FormControlLabel
                          control={
                            <CustomCheckbox
                              checked={secondSeriesAggregations.max}
                              onChange={() => handleAggregationChange('second', 'max')}
                              disabled={isLastCheckedAggregation(secondSeriesAggregations, 'max')}
                            />
                          }
                          label={t("progress.chart_form.maximum")}
                        />
                      </FormGroup>
                    </>
                  )}
                </SeriesConfigBox>
              )}
            </Box>
          )}

          {/* Axis Configuration */}
          {firstVariable && (
            <>
              <FormControl fullWidth>
                <InputLabel>{t("progress.chart_form.x_axis_scale")}</InputLabel>
                <Select
                  value={xAxisType}
                  onChange={(e) => setXAxisType(e.target.value)}
                  label={t("progress.chart_form.x_axis_scale")}
                >
                  {getAvailableXAxisTypes().map(type => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel>{t("progress.chart_form.y_axis_scale")}</InputLabel>
                <Select
                  value={yAxisType}
                  onChange={(e) => setYAxisType(e.target.value)}
                  label={t("progress.chart_form.y_axis_scale")}
                >
                  {availableYAxisTypes.map(type => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle2" gutterBottom>
                  {t("progress.chart_form.preview")}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Typography variant="body2">{t("progress.chart_form.x_axis_label")}:</Typography>
                    <EditableAxisLabel
                      label={xAxisLabel}
                      onLabelChange={setXAxisLabel}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Typography variant="body2">{t("progress.chart_form.y_axis_label")}:</Typography>
                    <EditableAxisLabel
                      label={yAxisLabel}
                      onLabelChange={setYAxisLabel}
                    />
                  </Box>
                  <ChartPreview
                    chartSeries={standardizeSeriesDataUnits([
                      firstVariable && {
                        id: firstVariable,
                        data: firstQuery.data,
                        dimension: firstVariableInfo?.typeConfig?.dimension,
                        unit: firstVariableInfo?.typeConfig?.unit,
                        label: firstLabel,
                        type: firstChartType,
                        color: firstColor,
                        isLoading: firstQuery.isLoading,
                        aggregations: firstSeriesAggregations
                      },
                      secondVariable && {
                        id: secondVariable,
                        data: secondQuery.data,
                        dimension: secondVariableInfo?.typeConfig?.dimension,
                        unit: secondVariableInfo?.typeConfig?.unit,
                        label: secondLabel,
                        type: secondChartType,
                        color: secondColor,
                        isLoading: secondQuery.isLoading,
                        aggregations: secondSeriesAggregations
                      }
                    ].filter(Boolean))}
                    firstChartType={firstChartType}
                    xAxisType={xAxisType}
                    yAxisType={yAxisType}
                    xAxisLabel={xAxisLabel}
                    yAxisLabel={yAxisLabel}
                    onSeriesChange={handleSeriesChange}
                  />
                </Box>
              </Box>
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>{t("progress.cancel")}</Button>
        <Button
          onClick={handleSave}
          variant="contained"
          disabled={!name || !selectedEvaluation || !firstVariable}
        >
          {t("progress.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChartFormModal; 