import React, { useState, useRef, useEffect } from 'react';
import { Typography, TextField, ClickAwayListener } from '@mui/material';

const EditableAxisLabel = ({ label, onLabelChange }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(label);
  const inputRef = useRef(null);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleChange = (event) => {
    setEditValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    } else if (event.key === 'Escape') {
      handleCancel();
    }
  };

  const handleSubmit = () => {
    if (editValue.trim() !== '') {
      onLabelChange(editValue.trim());
    }
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditValue(label);
    setIsEditing(false);
  };

  if (isEditing) {
    return (
      <ClickAwayListener onClickAway={handleSubmit}>
        <TextField
          ref={inputRef}
          value={editValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          size="small"
          autoFocus
          sx={{ width: '150px' }}
        />
      </ClickAwayListener>
    );
  }

  return (
    <Typography
      onDoubleClick={handleDoubleClick}
      sx={{ 
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
        padding: '4px',
        borderRadius: '4px',
      }}
    >
      {label}
    </Typography>
  );
};

export default EditableAxisLabel; 