import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Unstable_Grid2 as Grid,
  Paper,
  Divider,
  TextField,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Switch,
  Tooltip,
  Box
} from "@mui/material";
import {
  DeleteOutlined,
  ContentCopyOutlined,
  RadioButtonCheckedOutlined,
  FormatAlignLeftOutlined,
  LibraryAddCheckOutlined,
  // LooksOneOutlined,
  // LinearScaleOutlined,
  // LayersOutlined,
  StorageOutlined,
  ArrowDropDown,
  AddPhotoAlternateOutlined,
  CalendarToday
} from "@mui/icons-material"
import SelectionTemplate from "./SelectionTemplate";
// import RangeTemplate from "./RangeTemplate";
import DataTemplate from "./DataTemplate";
import TextTemplate from "./TextTemplate";

const SELECT_VALUES = (t) => ({
  SINGLE: {
    text: t("template.activity.types.single"),
    icon: <RadioButtonCheckedOutlined />,
    defaultParams: [{ text: "", value: 1, valid: false }],
    template: SelectionTemplate,
  },
  DATA: {
    text: t("template.activity.types.data"),
    icon: <StorageOutlined />,
    defaultParams: [{ required: true, valid: true }],
    defaultConfig: { dimension: "DISTANCE", unit: "m" },
    template: DataTemplate,
  },
  // NUMBER: {
  //   text: t("template.activity.types.number"),
  //   icon: <LooksOneOutlined />,
  //   defaultParams: [{ value: { min: 0, max: 100 }, valid: true }],
  //   template: null,
  // },
  DATE: {
    text: t("template.activity.types.date"),
    icon: <CalendarToday />,
    defaultParams: [{ value: { maxDateToday: true, format: "DD/MM/YYYY" }, valid: true }],
  },
  MULTIPLE: {
    text: t("template.activity.types.multiple"),
    icon: <LibraryAddCheckOutlined />,
    defaultParams: [{ text: "", value: 1, valid: false }],
    template: SelectionTemplate,
    other: { multiple: true }
  },
  // RANGE: {
  //   text: t("template.activity.types.range"),
  //   icon: <LayersOutlined />,
  //   defaultParams: [{ value: { min: 1, max: 5, step: 1 } }],
  //   template: RangeTemplate,
  // },
  // SLIDER: {
  //   text: t("template.activity.types.slider"),
  //   icon: <LinearScaleOutlined />,
  //   defaultParams: [{ value: { min: 1, max: 5, step: 1 } }],
  //   template: RangeTemplate,
  // },
  TEXTQUESTION: {
    text: t("template.activity.types.text"),
    icon: <FormatAlignLeftOutlined />,
    defaultParams: [{ value: 50, valid: true }],
    template: TextTemplate,
  },
})

const BlockTemplate = ({
  block,
  updateBlock,
  sectionIndex,
  blockIndex,
  removeBlock,
  duplicateBlock,
  canDelete = false,
  readOnly = false,
}) => {
  const parseMedia = (data) => {
    return data.map((image) => ({ image: image.src, file: { name: image.src } }))
  }

  const { t } = useTranslation("evaluations_new");
  const [anchorEl, setAnchorEl] = useState(null);
  const fileInputRef = useRef(null);
  const [hover, setHover] = useState([]);

  const [type, setType] = useState(block?.type || "SINGLE");
  const [title, setTitle] = useState(block?.title || "");
  const [titleError, setTitleError] = useState(false);
  const [description, setDescription] = useState(block?.description || "");
  const [media, setMedia] = useState(block?.media ? parseMedia(block.media) : []);
  const [required, setRequired] = useState(block?.required || false);
  const [typeConfig, setTypeConfig] = useState(block?.typeConfig || SELECT_VALUES(t).SINGLE.defaultConfig);
  const [params, setParams] = useState(block?.params || SELECT_VALUES(t).SINGLE.defaultParams);
  const valid = useMemo(() => {
    // DATA questions need to have at least 1 answer field
    if (type === "DATA") {
      return title.length && params.length && params.every((param) => param.valid)
    } else if (params.length) {
      return title.length && params.every((param) => param.valid)
    }
    return title.length
  }, [title, params]);

  const selectedValue = useMemo(() => {
    return SELECT_VALUES(t)[type]
  }, [type]);

  useEffect(() => {
    updateBlock({
      type,
      typeConfig,
      title,
      description,
      required,
      media: media.map((med) => med.file),
      params,
      valid,
      sectionIndex,
      blockIndex
    });
  }, [type, typeConfig, title, description, required, media, params, valid])

  const setDefaultParams = (newType) => {
    setParams(SELECT_VALUES(t)[newType].defaultParams);
    setType(newType);
    setTypeConfig(SELECT_VALUES(t)[newType].defaultConfig);
    setAnchorEl(null);
  };

  const updateTitle = (event) => {
    setTitle(event.target.value);
    setTitleError(!event.target.validity.valid);
  }

  const updateHover = (idx, value) => {
    const hoverCopy = [...hover];
    hoverCopy[idx] = value;
    setHover(hoverCopy);
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const mediaCopy = [...media];
      const hoverCopy = [...hover];
      mediaCopy.push({ file, image: URL.createObjectURL(file) });
      hoverCopy.push(false);
      setMedia(mediaCopy);
      setHover(hoverCopy);
    }
  }

  const removeFile = (idx) => {
    const mediaCopy = [...media];
    const hoverCopy = [...hover];
    mediaCopy.splice(idx, 1);
    hoverCopy.splice(idx, 1);
    setMedia(mediaCopy);
    setHover(hoverCopy);
  }

  return (
    <Grid>
      <Paper
        elevation={3}
        square={false}
      >
        <Grid container paddingX={5} paddingY={3} spacing={1}>
          <Grid md={8} sm={12}>
            <TextField
              required
              disabled={readOnly}
              variant="standard"
              placeholder={t("template.activity.questionTitle")}
              value={title}
              onChange={(e) => updateTitle(e)}
              fullWidth={true}
              error={titleError}
              helperText={titleError && t("template.activity.validation.empty")}
            />
          </Grid>
          <Grid md={4} sm={12}>
            <Button
              disabled={readOnly}
              variant="contained"
              onClick={(e) => setAnchorEl(e.currentTarget)}
              fullWidth
              sx={{ justifyContent: "space-between" }}
            >
              <span style={{ display: "flex", alignItems: "center", gap: 8 }}>
                {selectedValue.icon}
                {selectedValue.text}
              </span>
              <ArrowDropDown />
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              elevation={10}
              slotProps={{ paper: { sx: { width: anchorEl ? anchorEl.offsetWidth : "auto" } } }}
            >
              {Object.keys(SELECT_VALUES(t)).reduce((acc, option, index) => {
                acc.push(
                  <MenuItem
                    key={option}
                    onClick={() => setDefaultParams(option)}>
                    {SELECT_VALUES(t)[option].icon} {SELECT_VALUES(t)[option].text}
                  </MenuItem>
                );
                if (index === 1) {
                  acc.push(<Divider key={`divider-${index}`} />);
                }
                return acc;
              }, [])}
            </Menu>
          </Grid>
        </Grid>
        <Grid container paddingX={5} spacing={1}>
          <Grid xs={11}>
            <TextField
              disabled={readOnly}
              variant="standard"
              placeholder={t("template.activity.description")}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth={true}
            />
          </Grid>
          <Grid xs={1}>
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={(e) => handleFileChange(e)}
            />
            <IconButton
              disabled={readOnly}
              size="large"
              onClick={() => fileInputRef.current.click()}
            >
              <AddPhotoAlternateOutlined fontSize="large" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container paddingX={5} paddingY={1} columnSpacing={1} sx={{ justifyContent: "center" }}>
          {media.map((med, idx) => (
            <Grid key={med.file.name}>
              <Box
                position="relative"
                display="inline-block"
                onMouseEnter={() => updateHover(idx, true)}
                onMouseLeave={() => updateHover(idx, false)}
              >
                <Box
                  component="img"
                  src={med.image}
                  alt="Preview"
                  sx={{ maxHeight: 150, width: "auto" }}
                />
                {(!readOnly && hover[idx]) && (
                  <IconButton
                    size="small"
                    sx={{
                      position: "absolute",
                      top: 4,
                      right: 4,
                      backgroundColor: "rgba(255, 255, 255, 0.7)",
                      '&:hover': { backgroundColor: "rgba(255, 255, 255, 1)" }
                    }}
                    onClick={() => removeFile(idx)}
                  >
                    <DeleteOutlined fontSize="small" color="primary" />
                  </IconButton>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
        <Grid paddingX={5} paddingBottom={3}>
          {SELECT_VALUES(t)[type].template && (
            <selectedValue.template
              config={typeConfig}
              setConfig={setTypeConfig}
              params={params}
              updateParams={setParams}
              readOnly={readOnly}
              {...selectedValue.other}
            />
          )}
        </Grid>
        <Grid paddingX={2}>
          <Divider />
        </Grid>
        <Grid
          container
          padding={2}
          spacing={1}
          sx={{
            justifyContent: "flex-end",
            alignItems: "center"
          }}>
          <Grid>
            <Tooltip title={t("template.activity.duplicate")}>
              <span>
                <IconButton
                  size="large"
                  disabled={readOnly}
                  onClick={() => duplicateBlock(sectionIndex, blockIndex)}
                >
                  <ContentCopyOutlined />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          <Grid>
            <Tooltip title={t("template.activity.delete")}>
              <span>
                <IconButton
                  size="large"
                  disabled={readOnly || !canDelete}
                  onClick={() => removeBlock(sectionIndex, blockIndex)}
                >
                  <DeleteOutlined />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid>
            <Typography variant="subtitle" color={readOnly ? "gray" : "black"}>
              {t("template.activity.required")}
            </Typography>
          </Grid>
          <Grid>
            <Switch
              disabled={readOnly}
              checked={required}
              onClick={() => setRequired(!required)}
            />
          </Grid>
        </Grid>
      </Paper >
    </Grid >
  )
}

export default BlockTemplate;