import React from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  ClassicEditor,
  Essentials,
  Paragraph,
  Bold,
  Italic,
  Strikethrough,
  Underline,
  List,
  Indent,
  BlockQuote,
  Link,
  Heading,
  FontFamily
} from 'ckeditor5';
import { useTranslation } from "react-i18next";
import 'ckeditor5/ckeditor5.css';

const QuillComponent = ({ onChange, value }) => {
  const change = (content) => {
    if (onChange !== undefined) {
      onChange(content);
    }
  };
  const { t } = useTranslation("global");

  return (
    <CKEditor
      editor={ClassicEditor}
      data={value}
      onReady={(editor) => {
        editor.editing.view.change((writer) => {
          writer.setStyle("min-height", "200px", editor.editing.view.document.getRoot())
        })
      }}
      onChange={(_, editor) => {
        change(editor.getData());
      }}
      config={{
        licenseKey: "GPL",
        language: t("idiom"),
        plugins: [
          Essentials,
          Paragraph,
          Bold,
          Italic,
          Strikethrough,
          Underline,
          List,
          Indent,
          BlockQuote,
          Link,
          Heading,
          FontFamily
        ],
        toolbar: [
          "undo", "redo",
          "|",
          "bold", "italic", "underline", "strikethrough",
          "|",
          'numberedList', 'bulletedList',
          "|",
          "outdent", "indent",
          "|",
          "blockquote",
          "|",
          "link",
          "|",
          "fontFamily",
          "|",
          "heading"
        ],
      }}
    />
  );
}

export default QuillComponent;
