import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#281B65',
    },
    secondary: {
      main: '#EAE5FF',
    },
    tableHeader: {
      main: '#EEF2FF'
    },
    tableRow: {
      main: "#FFFFFF"
    },
    modalBackground: {
      main: "#F6F6F6"
    },
    black: {
      main: "#000000"
    }
  },
  typography: {
    fontFamily: '"Roboto", "Arial", sans-serif',
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: 'red',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
          },
          "& .MuiOutlinedInput-root.Mui-disabled": {
            backgroundColor: "#e0e0e0",
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        contained: {
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          textTransform: "none",
          minHeight: "40px",
          borderRadius: "8px"
        },
        outlined: {
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          textTransform: "none",
          minHeight: "40px",
          borderRadius: "8px"
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: ({ theme }) => ({
          "& th": {
            backgroundColor: theme.palette.tableHeader.main,
          }
        })
      }
    },
    MuiTableBody: {
      styleOverrides: {
        root: ({ theme }) => ({
          "& tr": {
            backgroundColor: theme.palette.tableRow.main,
          }
        })
      }
    }
  }
});

export default theme;