import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Unstable_Grid2 as Grid,
  Typography,
  TextField
} from "@mui/material";

const TextTemplate = ({
  params,
  updateParams,
  readOnly = false
}) => {
  const { t } = useTranslation("evaluations_new");
  const [maximumLength, setMaximumLength] = useState(params[0].value);

  const updateMaximumLength = (e) => {
    const parsed = e.target.value.replace(/[^0-9]/g, "");
    updateParams([{
      value: +parsed,
      valid: +parsed > 0,
    }]);
    setMaximumLength(+parsed);
  }

  return (
    <Grid
      container
      spacing={2}
      sx={{ alignItems: "center" }}
    >
      <Grid>
        <Typography variant="body2">
          {t("template.activity.text.maximumLength")}
        </Typography>
      </Grid>
      <Grid xs={2}>
        <TextField
          disabled={readOnly}
          variant="outlined"
          value={maximumLength}
          onChange={(e) => updateMaximumLength(e)}
        />
      </Grid>
    </Grid>
  )
}

export default TextTemplate;