import React, { useState, useEffect, useCallback } from "react";

/**
 * @returns {Object} containerRef: The ref of the container
 * @returns {Object} containerBounds: The bounds of the container
 * @description This hook is used to get the bounds of the container
 * @example
 * const { containerRef, containerBounds } = useContainerBounds();
 * 
 * <div ref={containerRef}>
 *  <div>
 *    <h1>Hello</h1>
 *  </div>
 * </div>
 */
export const useContainerBounds = () => {
  const [containerBounds, setContainerBounds] = useState(null);
  const containerRef = React.useRef(null);

  const updateBounds = useCallback(() => {
    if (containerRef.current) {
      const bounds = containerRef.current.getBoundingClientRect();
      setContainerBounds(bounds);
    }
  }, []);

  useEffect(() => {
    updateBounds();
    window.addEventListener('scroll', updateBounds);
    window.addEventListener('resize', updateBounds);

    return () => {
      window.removeEventListener('scroll', updateBounds);
      window.removeEventListener('resize', updateBounds);
    };
  }, [updateBounds]);

  return { containerRef, containerBounds };
};
