import React, { useState, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Unstable_Grid2 as Grid,
  Dialog,
  DialogContent,
  Typography,
  TextField,
  MenuItem,
  DialogActions,
  Button,
  CircularProgress
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import GoogleMapsInput from "./GoogleMapsInput";
import {
  tenantIdOption as nationalities,
  rutValidation
} from "../../../lib/validation.helpers";
import { useCreatePatient, useUpdatePatient } from "../../../queries/patient";

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const handleAddress = (patient) => {
  return {
    address: patient.address,
    structured_formatting: {
      main_text: patient.address,
      main_text_matched_substrings: [
        {
          offset: 0,
          length: patient.address.length
        }
      ],
      secondary_text: ""
    },
    commune: patient.commune,
    region: patient.region,
    country: patient.country
  }
}

// Some patients have bugged nationality data [object Object] instead of a country code
// Those we default to TrainfesID
const handleNationality = (patient) => {
  if (typeof patient.nationality === "string" && patient.nationality !== "[object Object]") {
    return patient.nationality
  }
  return "TFID";
}

const CreateOrEditPatientDialog = ({
  page,
  search,
  dialogOpen,
  closeDialog,
  existingData = null
}) => {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const { t } = useTranslation("registers");
  const userId = useRef(existingData?._id ?? null);
  const [nationality, setNationality] = useState(
    existingData ? handleNationality(existingData) : ""
  );
  const [rut, setRut] = useState(existingData?.rut ?? "");
  const [validRut, setValidRut] = useState(true);
  const [name, setName] = useState(existingData?.name ?? "");
  const [lastName, setLastName] = useState(existingData?.lastname ?? "");
  const [email, setEmail] = useState(existingData?.email ?? "");
  const [validEmail, setValidEmail] = useState(true);
  const [phone, setPhone] = useState(existingData?.phone ?? "");
  const [validPhone, setValidPhone] = useState(true);
  const [address, setAddress] = useState(
    existingData ? handleAddress(existingData) : null
  );
  const [apartment, setApartment] = useState(existingData?.other_address ?? "");
  const [birthday, setBirthday] = useState(
    existingData ? dayjs(existingData.birthdate, "D-M-YYYY") : null
  );
  const [gender, setGender] = useState(existingData?.genre ?? "");
  const [referral, setReferral] = useState(existingData?.reference ?? "");

  const resetState = () => {
    setNationality("");
    setRut("");
    setName("");
    setLastName("");
    setEmail("");
    setPhone("")
    setApartment("");
    setAddress(null);
    setBirthday(null);
    setGender("");
    setReferral("");
  }

  const genderOptions = [
    { value: "Masculino", label: t("gender.male") },
    { value: "Femenino", label: t("gender.female") },
  ]

  const onRutChange = (newRut) => {
    setRut(newRut);
    setValidRut(rutValidation(nationality, newRut));
  }

  const onEmailChange = (newEmail) => {
    setEmail(newEmail);
    setValidEmail(emailRegex.test(newEmail));
  }

  const onPhoneChange = (newPhone) => {
    setValidPhone(newPhone.replace(/\D/g, '').length >= 10);
    setPhone(newPhone);
  }

  const handleClose = () => {
    return closeDialog();
  }

  const createPatientMutation = useCreatePatient(page, search, {
    onSuccess: () => {
      resetState();
      handleClose();
    },
    onError: (err) => {
      setErrorMessage(err.message);
      setError(true);
    }
  });

  const updatePatientMutation = useUpdatePatient(page, search, {
    onSuccess: () => {
      resetState();
      handleClose();
    },
    onError: (err) => {
      setErrorMessage(err.message);
      setError(true);
    }
  })

  const isMutationLoading = createPatientMutation.isLoading || updatePatientMutation.isLoading;

  const validForm = useMemo(() => {
    return (
      validRut &&
      validEmail &&
      validPhone &&
      nationality &&
      name &&
      lastName &&
      address &&
      birthday &&
      gender
    )
  }, [nationality, validRut, name, lastName, validEmail, validPhone, address, birthday, gender])

  const savePatient = async () => {
    const formData = new FormData();
    formData.append("nationality", nationality);
    formData.append("rut", rut);
    formData.append("name", name);
    formData.append("lastname", lastName);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("genre", gender);
    formData.append("birthdate", birthday.format("DD-MM-YYYY"));
    formData.append("country", address.country);
    formData.append("region", address.region);
    formData.append("commune", address.commune);
    formData.append("rol", "patient");
    formData.append("address", address.address);
    formData.append("other_address", apartment);
    formData.append("reference", referral);
    if (userId.current) {
      await updatePatientMutation.mutateAsync({ data: formData, patientId: userId.current })
    } else {
      await createPatientMutation.mutateAsync({ data: formData });
    }
  }

  return (
    <Dialog
      open={dialogOpen}
      onClose={() => handleClose()}
      disableEscapeKeyDown={isMutationLoading}
      fullWidth
      maxWidth="sm"
    >
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container direction="column" spacing={1} padding={2}>
            {(!isMutationLoading && !error) && (<>
              <Grid>
                <Typography variant="h6">
                  {t("create_new_patient")}
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  disabled={!!existingData}
                  variant="outlined"
                  required
                  fullWidth
                  select
                  label={t("nationality")}
                  value={nationality}
                  onChange={(e) => setNationality(e.target.value)}
                >
                  {nationalities.map((nat) => (
                    <MenuItem key={nat.value} value={nat.value}>
                      {nat.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid>
                <TextField
                  disabled={!!existingData}
                  variant="outlined"
                  required
                  fullWidth
                  label={t("rut")}
                  value={rut}
                  onChange={(e) => onRutChange(e.target.value.replace(/\s/g, ""))}
                  error={!validRut}
                  helperText={!validRut && t("errors.rut")}
                />
              </Grid>
              <Grid>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  label={t("name")}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  label={t("last_name")}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Grid>
              <Grid>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  label={t("email")}
                  value={email}
                  onChange={(e) => onEmailChange(e.target.value)}
                  error={!validEmail}
                  helperText={!validEmail && t("errors.email")}
                />
              </Grid>
              <Grid>
                <MuiTelInput
                  required
                  fullWidth
                  label={t("phone")}
                  value={phone}
                  onChange={(newPhone) => onPhoneChange(newPhone)}
                  defaultCountry="CL"
                  error={!validPhone}
                  helperText={!validPhone && t("errors.phone")}
                />
              </Grid>
              <Grid>
                <GoogleMapsInput
                  required={true}
                  label={t("address")}
                  noResults={t("errors.address")}
                  value={address}
                  updateValue={setAddress}
                />
              </Grid>
              <Grid>
                <TextField
                  variant="outlined"
                  fullWidth
                  label={t("apartment")}
                  value={apartment}
                  onChange={(e) => setApartment(e.target.value)}
                />
              </Grid>
              <Grid>
                <DatePicker
                  value={birthday}
                  onChange={(v) => setBirthday(v)}
                  format="DD/MM/YYYY"
                  maxDate={dayjs()}
                  slotProps={{
                    inputAdornment: {
                      position: "start"
                    },
                    textField: {
                      label: t("birthday"),
                      fullWidth: true,
                      required: true,
                    }
                  }}
                />
              </Grid>
              <Grid>
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  select
                  label={t("gender.label")}
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  {genderOptions.map((gen) => (
                    <MenuItem key={gen.value} value={gen.value}>
                      {gen.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid>
                <TextField
                  variant="outlined"
                  fullWidth
                  label={t("referral")}
                  value={referral}
                  onChange={(e) => setReferral(e.target.value)}
                />
              </Grid>
            </>)}
            {(isMutationLoading) && (
              <Grid container sx={{ justifyContent: "center" }}>
                <Grid>
                  <CircularProgress />
                </Grid>
              </Grid>)}
            {error && (<>
              <Grid>
                <Typography variant="h6">
                  {t("errors.failure")}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="body2">
                  {errorMessage}
                </Typography>
              </Grid>
            </>)}
          </Grid>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isMutationLoading}
          variant="outlined"
          onClick={() => closeDialog()}
        >
          {t("exit")}
        </Button>
        {(!isMutationLoading && !error) && (
          <Button
            disabled={!validForm}
            variant="contained"
            onClick={() => savePatient()}
          >
            {t("save")}
          </Button>)}
        {error && (<Button
          variant="contained"
          onClick={() => setError(false)}
        >
          {t("try_again")}
        </Button>)}
      </DialogActions>
    </Dialog>
  )
}

export default CreateOrEditPatientDialog;