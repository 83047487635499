import React, { useState, useEffect } from "react"
import axios from "axios"
import {
  Unstable_Grid2 as Grid,
  Button,
  TextField,
  InputAdornment
} from "@mui/material";
import {
  ArrowDropDown
} from "@mui/icons-material"
import { useTranslation } from "react-i18next";
import EvaluationSelectionModal from "./EvaluationSelectionModal";
import ResultTemplateSectionHeader from "./ResultTemplateSectionHeader";
import ResultTemplateHeader from "./ResultTemplateHeader";
import ResultTemplateSingle from "./ResultTemplateSingle";
import ResultTemplateData from "./ResultTemplateData";
import ResultTemplateMultiple from "./ResultTemplateMultiple";
import ResultTemplateText from "./ResultTemplateText";
import ResultTemplateDate from "./ResultTemplateDate";

const BLOCK_TYPES = {
  SINGLE: ResultTemplateSingle,
  DATA: ResultTemplateData,
  MULTIPLE: ResultTemplateMultiple,
  TEXTQUESTION: ResultTemplateText,
  DATE: ResultTemplateDate,
  TEXTCONTEXT: null
}

const EvaluationResultModal = ({
  patient,
  updateTemplateId,
  initializeSections,
  updateSections,
  updateEvaluatedAt,
  patientResult = null
}) => {
  const { t } = useTranslation("patient_evaluation");

  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);

  const [evaluations, setEvaluations] = useState(null);
  const [chosenEvaluation, setChosenEvaluation] = useState(null);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchResultTemplate = async () => {
      if (patientResult) {
        setLoading(true);
        try {
          const response = await axios.get(`/evaluation_template/${patientResult.evaluationTemplateId}`);
          setChosenEvaluation(response.data.template);
        } catch (error) {
          setErrorDialog(true);
        } finally {
          setLoading(false);
        }
      }
    }
    fetchResultTemplate();
  }, [])

  const fetchEvaluations = async (page) => {
    setLoading(true)
    try {
      const response = await axios.get(`/evaluation_template?page=${page}`)
      setEvaluations(response.data.templates)
      setTotalPages(response.data.pages)
      setCurrentPage(page)
    } catch (error) {
      setErrorDialog(true)
    } finally {
      setLoading(false)
    }
  }

  const openDialog = async () => {
    setDialogOpen(true)
    if (!evaluations) {
      await fetchEvaluations(currentPage)
    }
  }

  const closeDialog = (_event, reason) => {
    if (loading && reason === "backdropClick") {
      return;
    }
    setDialogOpen(false)
  }

  const getEvaluationsFromPage = async (_event, page) => {
    setLoading(true)
    await fetchEvaluations(page)
  }

  const selectEvaluation = (item) => {
    if (chosenEvaluation?._id === item._id) {
      return;
    }
    setChosenEvaluation(item)
    const emptySections = item.sections.map((section) => {
      return {
        _id: section._id,
        valid: false,
        blocks: section?.blocks.map((block) => {
          return {
            _id: block._id,
            valid: block.params.length === 0,
            params: block.params.map((param) => {
              return {
                _id: param._id,
                value: null,
                detail: {},
              }
            })
          }
        })
      }
    })
    updateTemplateId(item._id);
    initializeSections(emptySections);
  }

  const updateParams = (values, sectionIndex, blockIndex, validBlock) => {
    updateSections(
      values,
      sectionIndex,
      blockIndex,
      validBlock,
      chosenEvaluation.sections[sectionIndex].blocks[blockIndex].type // Gets the current block type
    );
  }

  const updateEvaluationDate = (evaluationDate) => {
    updateEvaluatedAt(evaluationDate);
  }

  return (
    <>
      <Grid
        container
        spacing={1}
        padding={2}
        sx={{
          alignItems: "center"
        }}>
        <Grid>
          <TextField
            disabled={true}
            variant="outlined"
            value={`${patient.name} ${patient.lastname}`}
            size="small"
          />
        </Grid>
        <Grid>
          {chosenEvaluation ?
            <TextField
              disabled={!!patientResult}
              variant="outlined"
              placeholder={t("evaluation.evaluation")}
              value={chosenEvaluation.sections[0].title}
              onClick={openDialog}
              InputProps={{
                readOnly: true,
                endAdornment: (!patientResult &&
                  <InputAdornment position="end">
                    <ArrowDropDown color="black" />
                  </InputAdornment>
                )
              }}
              size="small"
              sx={{ backgroundColor: "white" }}
            /> :
            <Button
              variant="outlined"
              color="black"
              onClick={openDialog}
              endIcon={<ArrowDropDown />}
            >
              {t("evaluation.select")}
            </Button>}
        </Grid>
      </Grid>
      <Grid container sx={{ justifyContent: "center" }}>
        <Grid container direction="column" spacing={1} padding={2} xs={12} md={6} xl={5}>
          {chosenEvaluation && (<Grid container direction="column" spacing={1}>
            {chosenEvaluation.sections.map((section, s_index) => (
              <Grid key={section._id} container direction="column" spacing={3}>
                <Grid>
                  <ResultTemplateSectionHeader
                    section={section}
                    updateEvaluationDate={updateEvaluationDate}
                    evaluatedDate={patientResult?.evaluatedAt}
                  />
                </Grid>
                {section.blocks.map((block, b_index) => {
                  const BlockComponent = BLOCK_TYPES[block.type];
                  return (<Grid key={block._id}>
                    <ResultTemplateHeader
                      block={block}
                      Content={
                        BlockComponent ?
                          <BlockComponent
                            block={block}
                            sectionIndex={s_index}
                            blockIndex={b_index}
                            updateParams={updateParams}
                            blockResult={patientResult?.sections[s_index].blocks[b_index] || null}
                          />
                          : null
                      }
                    />
                  </Grid>)
                })}
              </Grid>
            ))}
          </Grid>)}
        </Grid >
      </Grid>
      <EvaluationSelectionModal
        loading={loading}
        dialogOpen={dialogOpen}
        closeDialog={closeDialog}
        errorDialog={errorDialog}
        evaluations={evaluations}
        currentPage={currentPage}
        totalPages={totalPages}
        getEvaluationsFromPage={getEvaluationsFromPage}
        chooseEvaluation={selectEvaluation}
      />
    </>
  )
}

export default EvaluationResultModal;