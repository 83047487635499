// Adapted from https://mui.com/material-ui/react-autocomplete/#google-maps-place
import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  TextField,
  Autocomplete,
  Unstable_Grid2 as Grid,
  Typography
} from "@mui/material"
import { LocationOn } from "@mui/icons-material";
import { debounce } from "lodash";
import parse from "autosuggest-highlight/parse";

const fetchPlacesAPI = debounce(async (request, callback) => {
  const { suggestions } =
    await window.google.maps.places.AutocompleteSuggestion.fetchAutocompleteSuggestions(
      request,
    );
  callback(
    suggestions.map((suggestion) => {
      const place = suggestion.placePrediction;
      return {
        address: place.text.text,
        structured_formatting: {
          main_text: place.mainText.text,
          main_text_matched_substrings: place.mainText.matches.map((match) => ({
            offset: match.startOffset,
            length: match.endOffset - match.startOffset,
          })),
          secondary_text: place.secondaryText?.text,
        },
      };
    }),
  );
}, 300);

const emptyOptions = [];
let sessionToken;

export default function GoogleMapsInput({ label, value, updateValue, noResults, required = false }) {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState(emptyOptions);

  const parseGeocode = (geodata, place) => {
    const address = {
      ...place,
    }
    geodata.results[0].address_components.forEach((adr) => {
      if (adr.types.includes("country")) {
        address.country = adr.long_name;
      } else if (adr.types.includes("administrative_area_level_1")) {
        address.region = adr.long_name;
      } else if (adr.types.includes("administrative_area_level_3")) {
        address.commune = adr.long_name;
      }
    })
    return address;
  }

  // By default, the Authorization header is added for all axios requests in the main index.js
  // This causes CORS issues here, so we create a separate instance without authorization
  const findLocation = async (place) => {
    const encoded = place.address.trim().replace(/\s+/g, '+');
    const uninterceptedAxios = axios.create();
    const geoResults = await uninterceptedAxios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encoded}&key=${process.env.REACT_APP_MAPS_APIKEY}&region=cl`,
    );
    const parsed = parseGeocode(geoResults.data, place);
    updateValue(parsed);
    return parsed;
  }

  useEffect(() => {
    if (inputValue === "") {
      setOptions(value ? [value] : emptyOptions);
      return undefined;
    }
    let active = true;
    if (!sessionToken) {
      sessionToken = new window.google.maps.places.AutocompleteSessionToken();
    }
    fetchPlacesAPI({ input: inputValue, sessionToken }, (results) => {
      if (!active) {
        return;
      }
      let newOptions = [];
      if (results) {
        newOptions = results;
        if (value) {
          newOptions = [
            value,
            ...results.filter((result) => result.address !== value.address),
          ];
        }
      } else if (value) {
        newOptions = [value];
      }
      setOptions(newOptions);
    });
    return () => { active = false; };
  }, [value, inputValue]);

  return (
    <Autocomplete
      fullWidth
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.address
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText={noResults}
      isOptionEqualToValue={(option, value) => option.address === value.address}
      onChange={(_, newValue) => {
        if (newValue) {
          setOptions(newValue ? [newValue, ...options] : options);
          findLocation(newValue);
        } else {
          updateValue(null);
        }
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} fullWidth required={required} />
      )}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        const matches = option.structured_formatting.main_text_matched_substrings;

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );
        return (
          <li key={key} {...optionProps}>
            <Grid container sx={{ alignItems: "center" }}>
              <Grid sx={{ display: "flex", width: 44 }}>
                <LocationOn sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}>
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{
                      fontWeight: part.highlight
                        ? "fontWeightBold"
                        : "fontWeightRegular",
                    }}
                  >
                    {part.text}
                  </Box>
                ))}
                {option.structured_formatting.secondary_text ? (
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    {option.structured_formatting.secondary_text}
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
