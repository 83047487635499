import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Unstable_Grid2 as Grid,
  TextField,
  Typography,
  MenuItem
} from "@mui/material";

// This is the same as the UNITS in PatientEvaluationPage/components/ResultTemplateData.js
const UNITS = {
  DISTANCE: ["m", "cm", "mm"],
  TIME: ["h", "m", "s"],
  VOLUME: ["l", "cl", "ml"],
}

const DataTemplate = ({
  config,
  setConfig,
  params,
  updateParams,
  readOnly = false
}) => {
  const { t } = useTranslation("evaluations_new");
  const [dimension, setDimension] = useState(config?.dimension || "DISTANCE");
  const [unit, setUnit] = useState(config?.unit || (UNITS[dimension] ? UNITS[dimension][0] : ""));
  const [requiredResponses, setRequiredResponses] = useState(
    readOnly ? params.filter((item) => item.required).length : 1
  );

  const DATA_TYPES = [
    { text: t("template.activity.data.distance"), value: "DISTANCE" },
    { text: t("template.activity.data.time"), value: "TIME" },
    { text: t("template.activity.data.volume"), value: "VOLUME" },
    { text: t("template.activity.data.other"), value: "OTHER" }
  ]

  const parseParam = (param) => {
    return +param.replace(/[^0-9]/g, "")
  }

  const updateUnit = (newUnit) => {
    setUnit(newUnit);
    setConfig({ dimension, unit: newUnit });
  }

  const updateParamType = (newType) => {
    setDimension(newType);
    const newUnit = !!UNITS[newType] ? UNITS[newType][0] : "";
    setConfig({ dimension: newType, unit: newUnit });
    setUnit(newUnit);
  }

  const changeMaximumParams = (amount) => {
    const paramsCopy = [...params];
    const parsed = parseParam(amount);
    if (parsed > paramsCopy.length) {
      const newParams = Array.from({ length: parsed - paramsCopy.length }, () => ({
        required: false,
        valid: true
      }));
      paramsCopy.push(...newParams);
      updateParams(paramsCopy);
    } else if (parsed < paramsCopy.length) {
      if (parsed < requiredResponses) {
        setRequiredResponses(parsed);
      }
      updateParams(paramsCopy.slice(0, parsed));
    }
  };

  const changeRequiredParams = (amount) => {
    const paramsCopy = [...params];
    const parsed = parseParam(amount);
    paramsCopy.forEach((param, index) => {
      param.required = index < parsed;
    })
    setRequiredResponses(parsed > params.length ? params.length : parsed);
    updateParams(paramsCopy);
  };

  return (
    <Grid container direction="column">
      <Grid
        container
        direction="row"
        spacing={2}
      >
        <Grid>
          <TextField
            disabled={readOnly}
            select
            label={"Dimension"}
            variant="outlined"
            value={dimension}
            onChange={(e) => updateParamType(e.target.value)}
            InputProps={{ style: { fontWeight: "bold" } }}
            size="small"
          >
            {DATA_TYPES.map((item, idx) => {
              return (
                <MenuItem
                  key={idx}
                  value={item.value}
                >
                  {item.text}
                </MenuItem>
              )
            })}
          </TextField>
          </Grid>
          <Grid>
          {dimension !== "OTHER" && (
            <TextField
              disabled={readOnly}
              select
              label={"Unit"}
              variant="outlined"
              value={unit}
              onChange={(e) => updateUnit(e.target.value)}
              InputProps={{ style: { fontWeight: "bold" } }}
              size="small"
          >
            {UNITS[dimension].map((item, idx) => {
              return (
                <MenuItem
                  key={idx}
                  value={item}
                >
                  {item}
                </MenuItem>
              )
            })}
          </TextField>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        spacing={1}
        sx={{
          marginTop: 1,
          alignItems: "center"
        }}
      >
        <Grid>
          <Typography variant="body2">
            {t("template.activity.data.responseMaximum")}
          </Typography>
        </Grid>
        <Grid xs={2}>
          <TextField
            disabled={readOnly}
            variant="outlined"
            value={params.length}
            onChange={(e) => changeMaximumParams(e.target.value)}
            size="small"
            sx={{ fontWeight: "bold" }}
          />
        </Grid>
        <Grid>
          <Typography variant="body2">
            {t("template.activity.data.responseRequired")}
          </Typography>
        </Grid>
        <Grid xs={2}>
          <TextField
            disabled={readOnly}
            variant="outlined"
            value={requiredResponses}
            onChange={(e) => changeRequiredParams(e.target.value)}
            size="small"
            sx={{ fontWeight: "bold" }}
          />
        </Grid>
      </Grid>
      {(params.length === 0) && (<Grid padding={1}>
        <Typography
          variant="body2"
          color="red"
        >
          {t("template.activity.validation.data.responses")}
        </Typography>
      </Grid>)}
      <Grid
        container
        spacing={1}
      >
        {params.map((param, index) => (
          <Grid
            key={index}
            xs={4}
          >
            <TextField
              disabled
              variant="standard"
              label={t(`template.activity.data.units.value`)
                + ` ${index + 1} `
                + t(`template.activity.data.units.${dimension.toLowerCase()}`)
                + `${param.required ? "*" : ""}`}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default DataTemplate;