import React, { useState, useCallback, useEffect } from 'react';
import {
  Button,
  Pagination,
  Box,
  Typography,
  Skeleton
} from '@mui/material';
import axios from 'axios';
import { utils } from "@amir04lm26/react-modern-calendar-date-picker";
import { DatePickerComponent } from '../../../components/DatePickerv2';
import { RecordTable } from '../../ClinicalRecordsPage/components/RecordTable';
import { useTranslation } from "react-i18next";
import { handleDateFilter } from "../../ClinicalRecordsPage/clinicalRecords.helpers";

const LIMIT = 4;

const subtractMonths = (numOfMonths, date = new Date()) => {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() - numOfMonths);
  return newDate;
};

const getDefaultDateRange = () => {
  let from = subtractMonths(6);
  from.setSeconds(0);
  from.setMinutes(0);
  from.setHours(0);
  return {
    from: {
      year: from.getFullYear(),
      month: from.getMonth() + 1,
      day: from.getDate(),
    },
    to: utils().getToday(),
  };
};

// Loading skeleton component
const LoadingSkeleton = () => (
  <Box sx={{ px: 2 }}>
    {[...Array(3)].map((_, index) => (
      <Box key={index} sx={{ mb: 2 }}>
        <Skeleton variant="rectangular" height={60} sx={{ mb: 1 }} />
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Skeleton variant="text" width={100} />
          <Skeleton variant="text" width={150} />
        </Box>
      </Box>
    ))}
  </Box>
);

const RecordsTab = ({ idActual }) => {
  const { t } = useTranslation("registers");

  // Records state
  const [records, setRecords] = useState([]);
  const [recordActive, setRecordActive] = useState("");
  const [recordsLoading, setRecordsLoading] = useState(false);
  const [totalPagesRecord, setTotalPagesRecord] = useState(0);
  const [pageRecord, setPageRecord] = useState(1);

  // Date state
  const [dateRange, setDateRange] = useState(getDefaultDateRange());
  const [minimumDate, setMinimumDate] = useState();
  const [maximumDate, setMaximumDate] = useState();

  const handleGetRecords = useCallback((id, date, pageNum) => {
    if (!id) return;

    const controller = new AbortController();
    const { signal } = controller;
    setRecordsLoading(true);

    const fromDate = new Date(date.from.year, date.from.month - 1, date.from.day);
    const toDate = new Date(date.to.year, date.to.month - 1, date.to.day + 1);
    const dateISO = {
      from: fromDate.toISOString(),
      to: toDate.toISOString(),
    };

    axios
      .post(`/user/records/${id}/${pageNum}/${LIMIT}`, dateISO, { signal })
      .then(({ data }) => {
        setRecords(data.data);
        setRecordActive(data.data[0]);
        setTotalPagesRecord(data.totalPages);
      })
      .finally(() => setRecordsLoading(false))
      .catch((err) => {
        setRecords([]);
        setRecordActive("");
        if (err.name !== "AbortError") {
          console.error(err.message);
        }
      });

    return () => controller.abort();
  }, []);

  const clearFilterRecordTable = () => {
    const defaultRange = getDefaultDateRange();
    setDateRange(defaultRange);
    setMinimumDate(undefined);
    setMaximumDate(undefined);
    setPageRecord(1);
    handleGetRecords(idActual, defaultRange, 1);
  };

  const handleFromDateChange = (value) => {
    if (!value) return;

    setDateRange(prev => ({
      ...prev,
      from: value
    }));
    setMaximumDate(value); // Restrict "to" date to be after "from" date
  };

  const handleToDateChange = (value) => {
    if (!value) return;

    setDateRange(prev => ({
      ...prev,
      to: value
    }));
    setMinimumDate(value); // Restrict "from" date to be before "to" date
  };

  const handlePageChange = (_, newPage) => {
    setPageRecord(newPage);
    handleGetRecords(idActual, dateRange, newPage);
  };

  // Effect to fetch initial data when idActual changes
  useEffect(() => {
    if (idActual) {
      handleGetRecords(idActual, dateRange, pageRecord);
    }
  }, [idActual, handleGetRecords, pageRecord]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', minHeight: 'inherit' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: 1
        }}
      >
        <Typography sx={{ fontWeight: 500, letterSpacing: '1px', color: '#182024', mr: 2 }}>{t("filter_by_date_range")}</Typography>
        <DatePickerComponent
          height="36px"
          placeholder={t("from")}
          range={false}
          value={dateRange.from}
          onChange={handleFromDateChange}
          maximumDate={maximumDate}
        />
        <Box sx={{ margin: '0 5px' }} />
        <DatePickerComponent
          height="36px"
          placeholder={t("to")}
          range={false}
          value={dateRange.to}
          onChange={handleToDateChange}
          minimumDate={minimumDate}
        />
        <Box sx={{ margin: '0 5px' }} />
        <Button
          variant="outlined"
          onClick={clearFilterRecordTable}
        >
          {t("clear")}
        </Button>
      </Box>

      <Box sx={{ flex: 1, overflowY: 'auto', position: 'relative' }}>
        {recordsLoading ? (
          <LoadingSkeleton />
        ) : (
          <>
            {records
              .filter((item) => handleDateFilter(item, dateRange))
              .map((record, i) => (
                <RecordTable
                  key={`record-${i}`}
                  record={record}
                  recordActive={recordActive}
                  setRecordActive={setRecordActive}
                  t={t}
                />
              ))}

            {records.length === 0 && (
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'calc(100vh - 500px)',
              }}>
                <Typography>
                  {t("no_records")}
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>

      <Pagination
        count={totalPagesRecord}
        onChange={handlePageChange}
        page={pageRecord}
        shape="rounded"
        sx={{ margin: 'auto', mt: 2 }}
      />
    </Box>
  );
};

export default RecordsTab;