import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { chartsKeys } from './keys';
import { chartsQueries } from './queries';

export const usePatientCharts = (patientId) => {
  return useQuery(
    chartsKeys.patientCharts(patientId),
    () => chartsQueries.getPatientCharts({ patientId }),
    {
      enabled: !!patientId,
    }
  );
};

export const useChartData = (chartId, options = {}) => {
  return useQuery(
    chartsKeys.chartData(chartId),
    () => chartsQueries.getChartData({ chartId }),
    {
      enabled: !!chartId,
      ...options,
    }
  );
};

export const useCreateChart = (patientId, options = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: chartsQueries.createChart,
    onSuccess: () => {
      queryClient.invalidateQueries(chartsKeys.patientCharts(patientId));
      options.onSuccess?.();
    }
  });
};

export const useUpdateChart = (patientId, options = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: chartsQueries.updateChart,
    onSuccess: () => {
      queryClient.invalidateQueries(chartsKeys.patientCharts(patientId));
      options.onSuccess?.();
    }
  });
};

/**
 * Filter the data to exclude the single selection false values and the data with no value
 * @param {Array} data - The data to filter
 * @returns {Array} The filtered data
 */
const singleSelectionAndDataFilter = (data) => {
  return data?.filter(item => !!item.value && item.resultValue !== false);
};

export const useFilteredChartData = (chartId) => {
  const singleSelectionSelector = (data) => (data.map(item => ({
    ...item,
    data: singleSelectionAndDataFilter(item.data)
  })))

  return useChartData(chartId, {
    select: singleSelectionSelector
  })
}

export const useDeleteChart = (patientId) => {
  const queryClient = useQueryClient();

  return useMutation(
    chartsQueries.deleteChart,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(chartsKeys.patientCharts(patientId));
      }
    });
}

export const useParameterTimeSeries = (patientId, templateId, sectionId, blockId, options = {}) => {
  return useQuery(
    chartsKeys.parameterTimeSeries(patientId, templateId, sectionId, blockId),
    () => chartsQueries.getParameterTimeSeries({ patientId, templateId, sectionId, blockId }),
    {
      enabled: !!patientId && !!templateId && !!sectionId && !!blockId,
      ...options,
    }
  );
}

export const useFilteredParameterTimeSeries = (patientId, templateId, sectionId, blockId) => {
  return useParameterTimeSeries(patientId, templateId, sectionId, blockId, {
    select: (data) => singleSelectionAndDataFilter(data),
  });
};
