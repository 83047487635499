import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";
import {
  Unstable_Grid2 as Grid,
  TextField,
  Paper,
  useTheme,
  Fab
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TextFieldsIcon from '@mui/icons-material/TextFields';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import BlockTemplate from "./BlockTemplate";
import BlockTemplateContext from "./BlockTemplateContext";
import { cloneDeep } from "lodash";
import { useContainerBounds } from "../../../hooks";


const StyledFab = styled(Fab)(({ theme }) => ({
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: "#e0e0e0"
  }
}));


const EvaluationView = ({
  template,
  updateTemplate,
  readOnly
}) => {
  const theme = useTheme();
  const { containerRef, containerBounds } = useContainerBounds();

  const defaultBlock = {
    _id: v4(),
    type: "SINGLE",
    title: "",
    description: "",
    required: true,
    media: [],
    params: [{ text: "", value: 1, valid: false }],
    valid: false,
  }
  const { t } = useTranslation("evaluations_new");
  const [sections, setSections] = useState(
    template.sections.length ? template.sections : [
      {
        _id: v4(),
        title: "",
        description: "",
        blocks: [defaultBlock],
        valid: false,
      }
    ]);
  const [titleErrors, setTitleErrors] = useState(
    template.sections.length ? Array(template.sections.length).fill(false) : [false]
  );

  useEffect(() => {
    const templateCopy = { ...template };
    templateCopy.sections = sections;
    updateTemplate(templateCopy);
  }, [sections]);

  const validSection = (section) => {
    // Section is valid if it has a title and all blocks are valid, but also
    // if it has at least one block regarding a question: [SINGLE, DATA, ...]
    return section.title && section.blocks.some((block) => block.type !== "TEXTCONTEXT") && section.blocks.every((block) => block.valid);
  }

  const updateTitle = (event, index) => {
    const sectionsCopy = [...sections];
    sectionsCopy[index].title = event.target.value;
    sectionsCopy[index].valid = validSection(sectionsCopy[index]);
    const titleErrorCopy = [...titleErrors];
    titleErrorCopy[index] = !event.target.validity.valid;
    setSections(sectionsCopy);
    setTitleErrors(titleErrorCopy);
  }

  const updateDescription = (value, index) => {
    const sectionsCopy = [...sections];
    sectionsCopy[index].description = value;
    sectionsCopy[index].valid = validSection(sectionsCopy[index]);
    setSections(sectionsCopy);
  }

  const addBlock = (sectionIndex) => {
    const sectionsCopy = [...sections];
    sectionsCopy[sectionIndex].blocks.push(defaultBlock);
    setSections(sectionsCopy);
  }

  const addTextContextBlock = (sectionIndex) => {
    const sectionsCopy = [...sections];
    sectionsCopy[sectionIndex].blocks.push({
      _id: v4(),
      type: "TEXTCONTEXT",
      title: "",
      description: "",
      valid: false,
    });
    setSections(sectionsCopy);
  }

  const updateBlock = ({ type, typeConfig, title, description, required = false, media = [], params, valid, sectionIndex, blockIndex }) => {
    const sectionsCopy = [...sections];
    sectionsCopy[sectionIndex].blocks[blockIndex] = {
      _id: sections[sectionIndex].blocks[blockIndex]._id,
      type,
      typeConfig,
      title,
      description,
      required,
      media,
      params,
      valid
    }
    sectionsCopy[sectionIndex].valid = validSection(sectionsCopy[sectionIndex]);
    setSections(sectionsCopy);
  }

  const deleteBlock = (sectionIndex, blockIndex) => {
    const sectionsCopy = [...sections];
    sectionsCopy[sectionIndex].blocks.splice(blockIndex, 1);
    setSections(sectionsCopy);
  }

  const duplicateBlock = (sectionIndex, blockIndex) => {
    const sectionsCopy = [...sections];
    // Clone the block and remove the media
    sectionsCopy[sectionIndex].blocks.push({
      ...cloneDeep(sectionsCopy[sectionIndex].blocks[blockIndex]),
      media: [],
      _id: v4(),
    });
    setSections(sectionsCopy);
  }

  return (<Grid
    container
    sx={{
      justifyContent: "center",
      alignItems: "center",
    }}>
    <Grid
      ref={containerRef}
      container
      direction={"column"}
      padding={2}
      spacing={2}
      xl={5}
      md={7}
      xs={12}
    >
      {sections.map((section, s_idx) => (
        <Grid key={section._id}>
          <Grid
            container
            direction={"column"}
            spacing={3}
          >
            <Grid>
              <Paper elevation={3} square={false} sx={{ borderTop: `4px solid ${theme.palette.primary.main}` }}>
                <Grid container direction="column" paddingX={5} paddingY={3} rowSpacing={3}>
                  <Grid>
                    <TextField
                      required
                      disabled={readOnly}
                      error={titleErrors[s_idx]}
                      variant="standard"
                      placeholder={t("template.name")}
                      value={section.title}
                      onChange={(e) => updateTitle(e, s_idx)}
                      fullWidth
                      helperText={titleErrors[s_idx] && t("template.activity.validation.empty")}
                    />
                  </Grid>
                  <Grid>
                    <TextField
                      disabled={readOnly}
                      variant="standard"
                      placeholder={t("template.description")}
                      value={section.description}
                      onChange={(e) => updateDescription(e.target.value, s_idx)}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            {section.blocks.map((block, b_idx) => (
              <Grid key={block._id}>
                {block.type === "TEXTCONTEXT" ? (
                  <BlockTemplateContext
                    block={block}
                    updateBlock={updateBlock}
                    sectionIndex={s_idx}
                    blockIndex={b_idx}
                    canDelete={section.blocks.length > 1}
                    removeBlock={deleteBlock}
                    duplicateBlock={duplicateBlock}
                    readOnly={readOnly}
                  />
                ) : (
                  <BlockTemplate
                    block={block}
                    updateBlock={updateBlock}
                    sectionIndex={s_idx}
                    blockIndex={b_idx}
                    canDelete={section.blocks.length > 1}
                    removeBlock={deleteBlock}
                    duplicateBlock={duplicateBlock}
                    readOnly={readOnly}
                  />
                )}
              </Grid>
            ))}
          </Grid>
          {!readOnly && (<Grid
            container
            padding={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              position: 'fixed',
              left: `${containerBounds?.right - 30}px`,
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: theme.zIndex.fab,
              width: 'auto',
              visibility: containerBounds?.top < window.innerHeight && containerBounds?.bottom > 0 ? 'visible' : 'hidden'
            }}>
            <StyledFab
              aria-label="add-text-context"
              onClick={() => addTextContextBlock(s_idx)}
            >
              <TextFieldsIcon />
            </StyledFab>
            <StyledFab
              aria-label="add-question-block"
              onClick={() => addBlock(s_idx)}
            >
              <QuestionMarkIcon />
            </StyledFab>
          </Grid>)}
        </Grid>
      ))}
    </Grid >
  </Grid>)
}

export default EvaluationView;