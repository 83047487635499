import axios from 'axios';

export const evaluationQueries = {
  getPatientEvaluations: async ({ patientId, page }) => {
    const response = await axios.get(
      `evaluation_result/patient/${patientId}`,
      { params: { page } }
    );
    return {
      evaluations: response.data.evaluations,
      pages: response.data.pages,
      total: response.data.total,
      currentPage: response.data.currentPage
    };
  },
  createEvaluation: async (evaluationData) => {
    const response = await axios.post('evaluation_result', evaluationData);
    return response.data;
  }
};
