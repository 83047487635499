import React from 'react';
import {
  ResponsiveChartContainer,
  LinePlot,
  BarPlot,
  ScatterPlot,
  ChartsXAxis,
  ChartsYAxis,
  MarkPlot,
  ChartsLegend
} from '@mui/x-charts';
import { Box, CircularProgress } from '@mui/material';

const ChartRenderer = ({
  series,
  xAxes,
  yAxes,
  height = 300,
  onLegendClick,
  isLoading
}) => {
  if (isLoading || !series?.length) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ height, width: '100%' }}>
      <ResponsiveChartContainer
        series={series}
        xAxis={xAxes}
        yAxis={yAxes}
        margin={{ top: 70 }}
      >
        <BarPlot />
        <LinePlot />
        <ScatterPlot />
        <MarkPlot />
        {xAxes?.map((axis) => (
          <ChartsXAxis key={axis.id} id={axis.id} />
        ))}
        {yAxes?.map((axis) => (
          <ChartsYAxis key={axis.id} id={axis.id} />
        ))}
        <ChartsLegend
          onItemClick={onLegendClick}
          itemMarkWidth={20}
          itemMarkHeight={4}
          itemGap={8}
          labelStyle={{
            fontSize: 14,
          }}
          padding={0}
        />
      </ResponsiveChartContainer>
    </Box>
  );
};

export default ChartRenderer; 