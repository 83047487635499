import React, { useState } from "react"
import { useTranslation } from "react-i18next";
import {
  Unstable_Grid2 as Grid,
  TextField
} from "@mui/material";

const ResultTemplateText = ({
  block,
  sectionIndex,
  blockIndex,
  updateParams,
  blockResult = null
}) => {
  const { t } = useTranslation("patient_evaluation");

  const getResultValue = () => {
    return blockResult.params[0].value;
  }

  const [result, setResult] = useState(blockResult ? getResultValue() : "");

  const updateText = (value) => {
    const tempValue = value.length > block.params[0].value ? value.slice(0, block.params[0].value) : value;
    setResult(tempValue);
    const newResults = [{
      _id: block.params[0]._id,
      value: tempValue
    }]
    // Block is considered valid the answer is not empty
    updateParams(newResults, sectionIndex, blockIndex, !!(tempValue));
  }

  return (
    <Grid paddingTop={1}>
      <TextField
        disabled={!!blockResult}
        variant="standard"
        value={result}
        onChange={(e) => updateText(e.target.value)}
        placeholder={t("evaluation.activities.text.response")}
      />
    </Grid>
  )
}

export default ResultTemplateText;