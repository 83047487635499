import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BlockColor, ProfessionalContainer, RecordCardBody, RecordCardContainer, RecordCardExpand, RecordCardHeader } from "./styles";

export const RecordCard = ({ data }) => {
	const { t } = useTranslation("registers");
	const { date, profesional, therapist, comment } = data;

	const [showExpand, setShowExpand] = useState(false);

	const dateFormatFunc = () => {
		if (!date) return;
		const dateFormat = date.split("T")[0].split("-");
		return `${dateFormat[2]}/${dateFormat[1]}/${dateFormat[0]}`;
	};

	return (
		<RecordCardContainer>
			<RecordCardHeader>
				<ProfessionalContainer>
					{profesional
						? profesional.specialities.map((spec, i) => (
							<div key={i}>
								<BlockColor bg={spec.color} />
								<p className="_bold _profession">{spec.name}</p>
							</div>
						))
						: null}
				</ProfessionalContainer>

				<p className="_bold" style={{ placeSelf: "center" }}>
					{therapist}
				</p>

				<p className="_bold" style={{ placeSelf: "flex-end", alignSelf: "center" }}>
					<span>{t("date")}: </span>
					{dateFormatFunc()}
				</p>
			</RecordCardHeader>

			<RecordCardBody>
				<div dangerouslySetInnerHTML={{ __html: comment }}></div>
			</RecordCardBody>

			{/* EXPAND */}
			<RecordCardExpand>
				<div className="recordcard--expand" onClick={() => setShowExpand((prev) => !prev)}>
					<p className="recordcard--bold_text">{t("clinical_data")}</p>
					<svg
						width="10"
						height="10"
						viewBox="0 0 10 10"
						className={showExpand ? "recordcard--active" : ""}
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g clipPath="url(#clip0_190_40)">
							<path
								d="M5.495 2.35499L9.79469 6.65499C10.0684 6.92843 10.0684 7.37187 9.79469 7.64531C9.52094 7.91874 9.07781 7.91874 8.80437 7.64531L4.99969 3.84062L1.19531 7.6453C0.921875 7.91874 0.478437 7.91874 0.205 7.6453C-0.0684378 7.37187 -0.0684378 6.92843 0.205 6.65499L4.50469 2.35499C4.64156 2.21843 4.82063 2.14999 4.99969 2.14999C5.17875 2.14999 5.35813 2.21843 5.49469 2.35499L5.495 2.35499Z"
								fill="#FDAD34"
							/>
						</g>
						<defs>
							<clipPath id="clip0_190_40">
								<rect width="10" height="10" fill="white" transform="translate(10) rotate(90)" />
							</clipPath>
						</defs>
					</svg>
				</div>

				{showExpand && (
					<div className="recordcard--expand_body">
						<p className="recordcard--bold_text recordcard--m_4">{t("evaluations")}:</p>
						<div className="recordcard--expand_desc">
							{(!data.evaluations || data.evaluations.length === 0) && <p>{t("no_evaluations")}</p>}

							{data.evaluations &&
								data.evaluations.map((e) =>
									e.variables.map((evaluation, i) => (
										<p key={i}>
											{evaluation.name}:{" "}
											{(() => {
												switch (evaluation.type) {
													case 'bool':
														return <span>{evaluation.result ? t("yes") : t("no")}</span>;
													case 'multi_select':
														if (evaluation.result === undefined) {
															return <span>-</span>;
														}
														return <span>{evaluation.result + " "}</span>;
													case 'single_select':
														return <span>{evaluation.result + " "}</span>;
													case 'checkboxes':
														if (!evaluation.result) {
															return <span>-</span>;
														}
														if (evaluation.result === undefined) {
															return <span>-</span>;
														}
														if (typeof evaluation.result === 'string') {
															return <span>{evaluation.result}</span>;
														}
														if (Array.isArray(evaluation.result)) {
															if (evaluation.result.length === 1) {
																return <span>{evaluation.result[0]}</span>;
															}
															if (evaluation.result.length > 1) {
																return <span>{evaluation.result.join(", ")}</span>;
															}
														}
														return <span>-</span>;
													default:
														return evaluation.result || "-";
												}
											})()}
										</p>
									))
								)
							}
						</div>

						<p className="recordcard--bold_text recordcard--m_4">{t("files")}:</p>
						<div className="recordcard--expand_desc">
							{(!data.files || data.files.length === 0) && <p>{t("no_files")}</p>}

							{data.files.map((e, i) => (
								<p key={i}>
									<a href={e.src} target="_blank" rel="noopener noreferrer">
										{e.name || e.src.split("/")[e.src.split("/").length - 1]}
									</a>
								</p>
							))}
						</div>
					</div>
				)}
			</RecordCardExpand>
		</RecordCardContainer>
	);
};

RecordCard.propTypes = {};
