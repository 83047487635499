import axios from 'axios';

export const chartsQueries = {
  getPatientCharts: async ({ patientId }) => {
    const response = await axios.get(
      `/patient_charts/patient/${patientId}`
    );
    return response.data;
  },
  getChartData: async ({ chartId }) => {
    const response = await axios.get(
      `/patient_charts/${chartId}/data`
    );
    return response.data?.chartData;
  },
  deleteChart: async ({ chartId }) => {
    const response = await axios.delete(
      `/patient_charts/${chartId}`
    );
    return response.data;
  },
  createChart: async (chart) => {
    const response = await axios.post(
      `/patient_charts`,
      chart
    );
    return response.data;
  },
  updateChart: async (chart) => {
    const response = await axios.put(
      `/patient_charts/${chart._id}`,
      chart
    );
    return response.data;
  },
  getParameterTimeSeries: async ({ patientId, templateId, sectionId, blockId }) => {
    const response = await axios.post(
      '/patient_charts/parameter-time-series',
      {
        patientId,
        templateId,
        sectionId,
        blockId
      }
    );
    return response.data.data;
  }
};