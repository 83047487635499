import React, { useState } from "react"
import {
  Unstable_Grid2 as Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Dialog,
  DialogContent,
  IconButton
} from "@mui/material";
import {
  Close
} from "@mui/icons-material"

const ResultTemplateHeader = ({
  block,
  Content,
}) => {
  const [open, setOpen] = useState(false);
  const [dialogImage, setDialogImage] = useState(null);

  const openDialog = (img) => {
    setOpen(true);
    setDialogImage(img);
  }

  const closeDialog = () => {
    setOpen(false);
    setDialogImage(null);
  }

  return (
    <>
      <Card elevation={3}>
        <CardContent>
          <Grid container direction="column" padding={2} rowSpacing={1}>
            <Grid container sx={{
              justifyContent: "space-between",
              alignItems: "center"
            }}>
              <Typography variant="h5">
                {block.title}
              </Typography>
              {block.required && (<Typography variant="h5" color="red">
                *
              </Typography>)}
            </Grid>
            {block.description && (<Grid>
              <Typography variant="body2">
                {block.description}
              </Typography>
            </Grid>)}
            {block.media.length > 0 && (<Grid
              container
              spacing={1}
              sx={{ justifyContent: "center", alignItems: "center" }}
            >
              {block.media.map((media) => (<Grid key={media.src}>
                <CardMedia
                  component="img"
                  image={media.src}
                  sx={{ maxHeight: 150, width: "auto", cursor: "zoom-in" }}
                  onClick={() => openDialog(media.src)}
                />
              </Grid>))}
            </Grid>)}
            {Content}
          </Grid>
        </CardContent>
      </Card>
      <Dialog open={open} onClose={closeDialog} maxWidth="xl">
        <DialogContent>
          <IconButton
            onClick={closeDialog}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              color: "white",
              backgroundColor: "rgba(0,0,0,0.5)",
              "&:hover": { backgroundColor: "rgba(0,0,0,0.7)" }
            }}
          >
            <Close />
          </IconButton>
          <img
            src={dialogImage}
            alt="DialogImage"
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ResultTemplateHeader;