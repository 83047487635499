/**
 * Internal library to convert units of measurement.
 * 
 * @module units_converter
 * 
 * Current distance units:
 * - Meters
 * - Centimeters
 * - Millimeters
 */

/**
 * Distance conversion functions
 * @namespace distance
 */

/**
 * Convert meters to centimeters
 * @param {number} meters - The distance in meters
 * @returns {number} The distance in centimeters
 */
const metersToCentimeters = (meters) => {
  return meters * 100;
};

/**
 * Convert centimeters to meters
 * @param {number} centimeters - The distance in centimeters
 * @returns {number} The distance in meters
 */
const centimetersToMeters = (centimeters) => {
  return centimeters / 100;
};

/**
 * Convert meters to millimeters
 * @param {number} meters - The distance in meters
 * @returns {number} The distance in millimeters
 */
const metersToMillimeters = (meters) => {
  return meters * 1000;
};

/**
 * Convert millimeters to meters
 * @param {number} millimeters - The distance in millimeters
 * @returns {number} The distance in meters
 */
const millimetersToMeters = (millimeters) => {
  return millimeters / 1000;
};

/**
 * Convert millimeters to centimeters
 * @param {number} millimeters - The distance in millimeters
 * @returns {number} The distance in centimeters
 */
const millimetersToCentimeters = (millimeters) => {
  return millimeters / 10;
};

/**
 * Convert centimeters to millimeters
 * @param {number} centimeters - The distance in centimeters
 * @returns {number} The distance in millimeters
 */
const centimetersToMillimeters = (centimeters) => {
  return centimeters * 10;
};

/**
 * Distance conversion table
 * First key is the source unit, second key is the target unit
 */
const DISTANCE_CONVERTION_TABLE = {
  m: {
    cm: metersToCentimeters,
    mm: metersToMillimeters,
  },
  cm: {
    m: centimetersToMeters,
    mm: centimetersToMillimeters,
  },
  mm: {
    m: millimetersToMeters,
    cm: millimetersToCentimeters,
  },
}

export {
  metersToCentimeters,
  centimetersToMeters,
  metersToMillimeters,
  millimetersToMeters,
  millimetersToCentimeters,
  centimetersToMillimeters,
  DISTANCE_CONVERTION_TABLE,
};