import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./Expand.styles";

export const RowComponent = ({ data }) => {
  const { t } = useTranslation("modes", { useSuspense: false });
  const headers = Object.keys(data);

  const [showExpand, setShowExpand] = useState(false);

  const handleToggle = () => setShowExpand((prev) => !prev);

  const headerValidator = (mode, header) => {
    if (mode === "sequential" || mode === "sensor" || mode === "graphic") {
      if (header !== "series" && header !== "repetitions" && header !== "cycles" && header !== "contractions") return;
      return header;
    }

    if (mode === "treadmill" && mode === "cicloergometro") {
      if (header !== "minHeartRate" && header !== "maxHeartRate") return;
      return header;
    }

    if (mode === "biofeedback") {
      if (header !== "minimumHeartRate" && header !== "maxHeartRate" && header !== "series" && header !== "sensorType") return;
      return header;
    }

    if (mode === "evaluations") {
      if (header !== "inputs") return;
      return header;
    }

    if (mode === "video") {
      if (header !== "videos") return;
      return header;
    }

    if (mode === "records") {
      if (header !== "files") return;
      return header;
    }

    if (mode === "pause") {
      if (header !== "durationTime") return;
      return header;
    }

    if (mode === "warnings") {
      if (header !== "type" && header !== "description") return;
      return header;
    }

    if (mode === "bike" || mode === "rowing") {
      if (header !== "proportionalIntensity" && header !== "cpm" && header !== "potency") return;
      return header;
    }

    if (mode === "walkfes") {
      if (header !== "durationTime" && header !== "steps" && header !== "proportionalIntensity") return;
      return header;
    }

    if (mode === "bipedestacion") {
      if (header !== "sitStandTime" && header !== "standTime" && header !== "standSitTime") return;
      return header;
    }

    if (mode === "neuromodulation") {
      if (header !== "durationTime") return;
      return header;
    }

    if (mode === "FLEXIBILITY") {
      if (header !== "repetitions" && header !== "series") return;
      return header;
    }

    if (mode === "CARDIOVASCULAR") {
      if (header !== "repetitions" && header !== "series" && header !== "durationTime") return;
      return header;
    }

    if (mode === "GENERAL") {
      if (header !== "repetitions" && header !== "series") return;
      return header;
    }

    if (mode === "tens") {
      if (header !== "durationTime") return;
      return header;
    }
  };

  const handleFilterSubHeader = (mode, propName) => {
    if (mode === "video") {
      return propName === "name";
    }

    if (mode === "files" || mode === "records") {
      if (propName === "filename" || propName === "url") return propName;
      return false;
    }

    return propName;
  };

  return (
    <>
      <S.RowStyled onClick={handleToggle}>
        <S.ColStyled xs={3}>
          <S.Text>{t(data.mode.toLowerCase())}</S.Text>
        </S.ColStyled>
        <S.ColStyled xs={3}>
          <S.TimeText className="align-center">{data.durationTime || data.time || data.timeTrainning}</S.TimeText>
        </S.ColStyled>
        <S.ColStyled xs={3}>
          <S.Text className="align-center">{data.name}</S.Text>
        </S.ColStyled>
        <S.ColStyled xs={3}></S.ColStyled>
        <S.ArrowStyled className={showExpand ? "arrow__active" : ""} />
      </S.RowStyled>
      {showExpand && (
        <S.BodyExpand>
          {headers
            ? headers
              .filter((header) => headerValidator(data.mode, header) === header)
              .map((box, i) => {
                return (
                  <S.Box key={`box--${i}`}>
                    <S.HeaderCol>{t(`expand.${box}`)}</S.HeaderCol>
                    {Array.isArray(data[box]) ? (
                      data[box].map((item, i) => {
                        const subHeaders = Object.keys(item);
                        return (
                          <div key={`fragment--${i}`} style={{ display: "contents" }}>
                            {subHeaders
                              .filter((item) => handleFilterSubHeader(data.mode, item))
                              .map((sub, j) => {
                                return (
                                  <S.Text key={`subtxt---${j}`}>
                                    {item[sub] !== undefined
                                      ? box === "steps"
                                        ? i === 0 && j === 0
                                          ? `${data[box].length}`
                                          : null
                                        : `${t(`expand.${sub.toLowerCase()}`)}: ${item[sub]}`
                                      : 0}
                                  </S.Text>
                                );
                              })}

                            {/* Elimina el espacio final. */}
                            {box !== "steps" && i !== data[box].length - 1 ? <br key={`br--${i}`} /> : null}
                          </div>
                        );
                      })
                    ) : (
                      <S.Text key={`txt--${i}`}>{data[box] ? data[box] : 0}</S.Text>
                    )}
                  </S.Box>
                );
              })
            : null}
        </S.BodyExpand>
      )}
    </>
  );
};
