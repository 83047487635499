import axios from 'axios';

export const patientQueries = {
  getPatientList: async ({ page, limit, search }) => {
    const response = await axios.get(
      `user/getallpatients/${page}/${limit}`,
      { params: { search } }
    );
    return {
      patients: response.data.data,
      totalPages: response.data.totalPages
    };
  },
  createPatient: async ({ data }) => {
    try {
      const response = await axios.post("/user/create",
        data,
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      return response.data;
    } catch ({ data }) {
      throw new Error(data?.err?.err);
    }
  },
  updatePatient: async ({ data, patientId }) => {
    try {
      const response = await axios.put(`/user/updateuser/${patientId}`,
        data,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      return response.data;
    } catch ({ data }) {
      throw new Error(data?.err?.err);
    }
  },
}; 