import React, { useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import LegendItemEditor from './LegendItemEditor';
import {
  transformSeriesData,
  calculateYAxisRange,
  getAllUniqueDates
} from '../../utils/chartUtils';
import ChartRenderer from './ChartRenderer';

const ChartPreview = ({
  chartSeries,
  xAxisType = 'time',
  yAxisType = 'linear',
  xAxisLabel = 'Date',
  yAxisLabel = 'Value',
  onSeriesChange,
}) => {
  const [editingLegendItem, setEditingLegendItem] = useState(null);


  if (!chartSeries.length || chartSeries.some(s => s.isLoading)) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 300 }}>
        <CircularProgress />
      </Box>
    );
  }

  const transformedSeries = transformSeriesData(chartSeries, xAxisType);
  const yAxisRange = calculateYAxisRange(transformedSeries);
  const allDates = getAllUniqueDates(chartSeries);
  // Calculate X-axis configuration
  const xAxisConfig = {
    id: 'x-axis',
    scaleType: xAxisType,
    label: xAxisLabel,
    ...(xAxisType === 'time' && {
      data: allDates.map(date => new Date(date)),
      min: new Date(allDates[0]),
      max: new Date(allDates[allDates.length - 1])
    }),
    ...(xAxisType === 'band' && {
      data: allDates
    }),
    ...(xAxisType === 'point' && {
      data: allDates
    }),
    ...(xAxisType ==='linear' && {
      data: allDates.map((_, index) => index + 1)
    }),
  };

  const handleLegendClick = (event, legendItem) => {
    // Extract the base series ID by removing the aggregation suffix
    const baseSeriesId = legendItem.seriesId.split('-')[0];
    
    // Find the original series (without aggregation) that matches this label
    const originalSeries = chartSeries.find(s => s.id === baseSeriesId);
    
    if (originalSeries) {
      setEditingLegendItem({
        id: originalSeries.id,
        label: originalSeries.label,
        color: originalSeries.color
      });
    }
  };

  const handleLegendItemSave = (updatedItem) => {
    if (onSeriesChange) {
      onSeriesChange(updatedItem.id, {
        color: updatedItem.color,
        label: updatedItem.label
      });
    }
    
    setEditingLegendItem(null);
  };

  return (
    <Box sx={{ height: 300, width: '100%' }}>
      <ChartRenderer
        series={transformedSeries}
        xAxes={[xAxisConfig]}
        yAxes={[{
          id: 'left',
          scaleType: yAxisType,
          min: yAxisRange.min,
          max: yAxisRange.max,
          label: yAxisLabel,
        }]}
        onLegendClick={handleLegendClick}
        isLoading={chartSeries.some(s => s.isLoading)}
      />

      {editingLegendItem && <LegendItemEditor
        open={!!editingLegendItem}
        onClose={() => setEditingLegendItem(null)}
        item={editingLegendItem}
        onSave={handleLegendItemSave}
      />}
    </Box>
  );
};

export default ChartPreview; 