import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { patientKeys } from './keys';
import { patientQueries } from './queries';

export const usePatientList = (page, search, limit = 100) => {
  return useQuery(
    patientKeys.listPaginated(page, search),
    () => patientQueries.getPatientList({ page, limit, search }),
    {
      keepPreviousData: false, // Don't keep previous data when changing search/page
    }
  );
}

export const useCreatePatient = (page, search, options = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: patientQueries.createPatient,
    onSuccess: () => {
      queryClient.invalidateQueries(patientKeys.listPaginated(page, search));
      options.onSuccess?.();
    },
    onError: (error) => options.onError?.(error),
    useErrorBoundary: false,
  })
}

export const useUpdatePatient = (page, search, options = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: patientQueries.updatePatient,
    onSuccess: () => {
      queryClient.invalidateQueries(patientKeys.listPaginated(page, search));
      options.onSuccess?.();
    },
    onError: (error) => options.onError?.(error),
    useErrorBoundary: false,
  })
}