import React, { useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  Unstable_Grid2 as Grid,
  Card,
  CardContent,
  Typography,
  Divider,
  useTheme
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTranslation } from "react-i18next";

dayjs.extend(utc);

const ResultTemplateSectionHeader = ({
  section,
  updateEvaluationDate,
  evaluatedDate = false,
}) => {
  const { t } = useTranslation("patient_evaluation");
  const [evaluationDate, setEvaluationDate] = useState(dayjs.utc(evaluatedDate || undefined));
  const theme = useTheme();

  const updateDate = (value) => {
    const dateToSave = value > dayjs() ? dayjs() : value;
    updateEvaluationDate(dateToSave);
    setEvaluationDate(dateToSave);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Card
        elevation={3}
        sx={{ borderTop: `4px solid ${theme.palette.primary.main}` }}
      >
        <CardContent>
          <Grid container direction="column" padding={2} spacing={1}>
            <Grid container sx={{ alignItems: "center" }}>
              <Typography variant="h4">
                {section.title}
              </Typography>
            </Grid>
            {section.description && (<Grid container>
              <Typography variant="subtitle1">
                {section.description}
              </Typography>
            </Grid>)}
            <Grid container>
              <DatePicker
                disabled={!!evaluatedDate}
                value={evaluationDate}
                onChange={(v) => updateDate(v)}
                format="DD/MM/YYYY"
                maxDate={dayjs()}
                slotProps={{
                  inputAdornment: {
                    position: "start"
                  },
                  textField: {
                    sx: {
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "8px",
                        maxWidth: "163px"
                      },
                    },
                    size: "small"
                  }
                }}
              />
            </Grid>
            <Grid>
              <Divider />
            </Grid>
            <Grid>
              <Typography variant="body2" color="red">
                {t("evaluation.activities.section.required")}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </LocalizationProvider>
  )
}

export default ResultTemplateSectionHeader;