/**
 * Internal library to convert units of measurement.
 * 
 * @module units_converter
 * 
 * Current time units:
 * - Milliseconds
 * - Seconds
 * - Minutes
 * - Hours
 */

/**
 * Time conversion functions
 * @namespace time
 */

/**
 * Convert milliseconds to seconds
 * @param {number} milliseconds - The time in milliseconds
 * @returns {number} The time in seconds
 */
const millisecondsToSeconds = (milliseconds) => {
  return milliseconds / 1000;
};

/**
 * Convert seconds to milliseconds
 * @param {number} seconds - The time in seconds
 * @returns {number} The time in milliseconds
 */
const secondsToMilliseconds = (seconds) => {
  return seconds * 1000;
};

/**
 * Convert minutes to milliseconds
 * @param {number} minutes - The time in minutes
 * @returns {number} The time in milliseconds
 */
const minutesToMilliseconds = (minutes) => {
  return minutes * 60 * 1000;
};

/**
 * Convert milliseconds to minutes
 * @param {number} milliseconds - The time in milliseconds
 * @returns {number} The time in minutes
 */
const millisecondsToMinutes = (milliseconds) => {
  return milliseconds / 60 / 1000;
};

/**
 * Convert minutes to seconds
 * @param {number} minutes - The time in minutes
 * @returns {number} The time in seconds
 */
const minutesToSeconds = (minutes) => {
  return minutes * 60;
};

/**
 * Convert seconds to minutes
 * @param {number} seconds - The time in seconds
 * @returns {number} The time in minutes
 */
const secondsToMinutes = (seconds) => {
  return seconds / 60;
};

/**
 * Convert minutes to hours
 * @param {number} minutes - The time in minutes
 * @returns {number} The time in hours
 */
const minutesToHours = (minutes) => {
  return minutes / 60;
};

/**
 * Convert hours to minutes
 * @param {number} hours - The time in hours
 * @returns {number} The time in minutes
 */
const hoursToMinutes = (hours) => {
  return hours * 60;
};

/**
 * Convert hours to seconds
 * @param {number} hours - The time in hours
 * @returns {number} The time in seconds
 */
const hoursToSeconds = (hours) => {
  return hours * 60 * 60;
};

/**
 * Convert seconds to hours
 * @param {number} seconds - The time in seconds
 * @returns {number} The time in hours
 */
const secondsToHours = (seconds) => {
  return seconds / 60 / 60;
};

/**
 * Convert hours to milliseconds
 * @param {number} hours - The time in hours
 * @returns {number} The time in milliseconds
 */
const hoursToMilliseconds = (hours) => {
  return hours * 60 * 60 * 1000;
};

/**
 * Convert milliseconds to hours
 * @param {number} milliseconds - The time in milliseconds
 * @returns {number} The time in hours
 */
const millisecondsToHours = (milliseconds) => {
  return milliseconds / 60 / 60 / 1000;
};

/**
 * Time conversion table
 * First key is the source unit, second key is the target unit
 */
const TIME_CONVERTION_TABLE = {
  ms: {
    s: millisecondsToSeconds,
    m: millisecondsToMinutes,
    h: millisecondsToHours,
  },
  s: {
    ms: secondsToMilliseconds,
    m: secondsToMinutes,
    h: secondsToHours,
  },
  m: {
    ms: minutesToMilliseconds,
    s: minutesToSeconds,
    h: minutesToHours,
  },
  h: {
    ms: hoursToMilliseconds,
    s: hoursToSeconds,
    m: hoursToMinutes,
  },
}

export {
  millisecondsToSeconds,
  secondsToMilliseconds,
  minutesToMilliseconds,
  millisecondsToMinutes,
  minutesToSeconds,
  secondsToMinutes,
  minutesToHours,
  hoursToMinutes,
  hoursToSeconds,
  secondsToHours,
  hoursToMilliseconds,
  millisecondsToHours,
  TIME_CONVERTION_TABLE,
};

