import { v4 as uuidv4 } from 'uuid';
import {
  TIME_CONVERTION_TABLE,
  DISTANCE_CONVERTION_TABLE,
  VOLUME_CONVERTION_TABLE,
} from '../../../lib/units_converter';
import { cloneDeep } from 'lodash';
// Helper function to group data by day
export const groupDataByDay = (data) => {
  return data.reduce((acc, curr) => {
    const date = new Date(curr.date);
    const day = date.toISOString().split('T')[0];

    // Convert the value to a number
    const value = Number(curr.value);

    if (!acc[day]) {
      acc[day] = {
        date: day,
        min: value,
        max: value,
        average: value,
        values: [value]
      };
    } else {
      acc[day].min = Math.min(acc[day].min, value);
      acc[day].max = Math.max(acc[day].max, value);
      acc[day].values.push(value);
      acc[day].average = acc[day].values.reduce((a, b) => a + b) / acc[day].values.length;
    }

    return acc;
  }, {});
};

// Get all unique dates across all series
export const getAllUniqueDates = (series) => {
  const allDates = series.flatMap(s =>
    s.data?.map(point => new Date(point.date).toISOString().split('T')[0])
  ).filter(Boolean);
  return [...new Set(allDates)].sort();
};

// Helper function to adjust color brightness
const adjustColor = (color, amount) => {
  const hex = color.replace('#', '');
  const num = parseInt(hex, 16);
  const r = Math.min(255, Math.max(0, (num >> 16) + amount));
  const g = Math.min(255, Math.max(0, ((num >> 8) & 0x00FF) + amount));
  const b = Math.min(255, Math.max(0, (num & 0x0000FF) + amount));
  return `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, '0')}`;
};

// Helper function to convert value from one unit to another
const convertValue = (value, dimension, currentUnit, targetUnit) => {
  const convertionTable = dimension === 'TIME' ? TIME_CONVERTION_TABLE
  : dimension === 'DISTANCE' ? DISTANCE_CONVERTION_TABLE: dimension === 'VOLUME'
  ? VOLUME_CONVERTION_TABLE : null;

  if (!convertionTable || currentUnit === targetUnit) {
    return value;
  }
  if (!convertionTable[currentUnit] || !convertionTable[currentUnit][targetUnit]) {
    throw new Error(`Invalid unit: ${currentUnit} for dimension: ${dimension}`);
  }
  const convertionFunction = convertionTable[currentUnit][targetUnit];
  return convertionFunction(value);
}

// Helper function to standardize the units of the series data
export const standardizeSeriesDataUnits = (series) => {
  const standardizedSeries = cloneDeep(series);
  standardizedSeries.forEach(s => {
    s.data?.forEach(point => {
      point.value = convertValue(point.value, s.dimension, point.unit, s.unit);
      point.unit = s.unit;
    });
  });
  return standardizedSeries;
}


// Transform series data including aggregations
export const transformSeriesData = (series, xAxisType) => {
  const allDates = getAllUniqueDates(series);

  return series.flatMap(s => {
    if (!s.data) return [];

    const groupedData = groupDataByDay(s.data);
    const baseColor = s.color;

    // For scatter plots, return single series
    if (s.type === 'scatter') {
      return [{
        ...s,
        data: s.data.map((point, idx) => ({
          x: xAxisType === 'time' ? new Date(point.date) : xAxisType === 'linear' ? idx + 1 : new Date(point.date).toISOString().split('T')[0],
          y: point.value,
          id: `${idx}-${point.date}-${point.value}`,
        }))
      }];
    }

    // For line and bar charts, create series for each selected aggregation
    const aggregationSeries = [];
    if (s.aggregations?.average) {
      aggregationSeries.push({
        ...s,
        id: `${s.id || uuidv4()}-average`, // Unique identifier for the series
        label: `${s.label} (Avg)`,
        color: baseColor,
        data: allDates.map(date => {
          const dayData = groupedData[date];
          return dayData ? dayData.average : null;
        })
      });
    }

    if (s.aggregations?.min) {
      aggregationSeries.push({
        ...s,
        id: `${s.id || uuidv4()}-min`,
        label: `${s.label} (Min)`,
        color: adjustColor(baseColor, -60),
        data: allDates.map(date => {
          const dayData = groupedData[date];
          return dayData ? dayData.min : null;
        })
      });
    }

    if (s.aggregations?.max) {
      aggregationSeries.push({
        ...s,
        id: `${s.id || uuidv4()}-max`,
        label: `${s.label} (Max)`,
        color: adjustColor(baseColor, 60),
        data: allDates.map(date => {
          const dayData = groupedData[date];
          return dayData ? dayData.max : null;
        })
      });
    }

    return aggregationSeries;
  });
};

// Calculate Y-axis ranges for all chart types
export const calculateYAxisRange = (series) => {
  const allValues = series.flatMap(s =>
    s.data?.flatMap(point =>
      typeof point === 'object' ? point.y : point
    ).filter(v => v !== null)
  );

  const yMinValue = Math.min(...allValues);
  const yMaxValue = Math.max(...allValues);
  const yValueRange = yMaxValue - yMinValue;

  // For bar charts, we want to ensure 0 is included in the range
  // but we still need to show negative values if they exist
  const hasBarChart = series.some(s => s.type === 'bar');

  return {

    min: hasBarChart
      ? Math.min(0, yMinValue) - (Math.abs(yMinValue) * 0.05) // Include 0 and add padding for negatives
      : yMinValue - (yValueRange * 0.1),
    max: hasBarChart
      ? Math.max(0, yMaxValue) + (Math.abs(yMaxValue) * 0.05) // Include 0 and add padding for positives
      : yMaxValue + (yValueRange * 0.1)
  };
}; 
