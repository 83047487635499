import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
  TextField,
  InputAdornment,
  Button,
  Pagination,
  Fab,
  Box,
  Typography,
  Drawer,
  CircularProgress
} from '@mui/material';
import Add from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import { useHistory } from "react-router-dom";
import { RecordCard } from "../../ClinicalRecordsPage/components/RecordCard";
import { FilterSidebar } from "../../ClinicalRecordsPage/components/FilterSidebar";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { debounce } from 'lodash';

const LIMIT = 4;

const EvolutionsTab = ({ idActual }) => {
  const history = useHistory();
  const containerRef = useRef(null);
  const { t } = useTranslation([
    "registers",
    "patient_evaluation"
  ]);

  // Evolution states
  const [historical, setHistorical] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [dateFiltered, setDateFiltered] = useState({
    from: null,
    to: null,
  });
  const [specialityFilter, setSpecialityFilter] = useState("");

  // Filter states
  const [showSidebar, setShowSidebar] = useState(false);

  const handleGetEvolution = useCallback((id, page, searchQuery = "", dateFilter = "", specialityFilter = "") => {
    if (!id) return;

    const controller = new AbortController();
    const { signal } = controller;
    setIsLoading(true);

    const dateTo = dateFilter.to;
    const dateFrom = dateFilter.from;

    axios
      .get(`/user/evolution/${id}/${page}/${LIMIT}`, {
        signal,
        params: {
          search: searchQuery,
          from: dateFrom,
          to: dateTo,
          speciality: specialityFilter,
        }
      })
      .then(({ data }) => {
        setHistorical(data.data);
        setTotalPages(data.totalPages);
      })
      .finally(() => setIsLoading(false))
      .catch((err) => {
        setHistorical([]);
        if (err.name !== "AbortError") {
          console.error(err.message);
        }
      });

    return () => controller.abort();
  }, []);

  const debouncedGetEvolution = useCallback(
    debounce((id, page, value, dateFilter, speciality) => {
      handleGetEvolution(id, page, value, dateFilter, speciality);
    }, 1000),
    [handleGetEvolution]
  );

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    setPage(1);
    debouncedGetEvolution(idActual, 1, value, dateFiltered, specialityFilter);
  };

  const handleShowSidebar = () => setShowSidebar(true);
  const handleCloseSidebar = () => setShowSidebar(false);

  const handleFilterChange = (speciality, dateFilter) => {
    setPage(1);
    setDateFiltered(dateFilter);
    setSpecialityFilter(speciality);
    handleGetEvolution(idActual, 1, searchValue, dateFilter, speciality);
    handleCloseSidebar();
  };

  const handlePageChange = (_, newPage) => {
    setPage(newPage);
    handleGetEvolution(idActual, newPage, searchValue, dateFiltered, specialityFilter);
  };

  // Reset all states when idActual changes
  useEffect(() => {
    if (idActual) {
      const resetDateFilter = {
        from: null,
        to: null,
      };

      // Reset UI states
      setSearchValue("");
      setDateFiltered(resetDateFilter);
      setSpecialityFilter("");
      setHistorical([]);
      setTotalPages(0);
      setPage(1);

      // Fetch initial data with reset values
      handleGetEvolution(idActual, 1, "", resetDateFilter, "");
    }
  }, [idActual, handleGetEvolution]);

  const renderContent = () => {
    if (isLoading) {
      return (
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%'
        }}>
          <CircularProgress />
        </Box>
      );
    }

    if (historical.length === 0) {
      return (
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 'calc(100vh - 500px)',
        }}>
          <Typography>
            {t("patient_evaluation:evolution.no_records")}
          </Typography>
        </Box>
      );
    }

    return historical.map((r) => (
      <RecordCard key={r._id} data={r} />
    ));
  };

  const renderFooter = () => {
    return (
      <Box sx={{
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
        backgroundColor: "white",
        position: "sticky",
        bottom: 0,
        borderTop: 1,
        borderColor: 'divider',
      }}>
        {totalPages > 1 && (
          <Pagination
            count={totalPages}
            onChange={handlePageChange}
            sx={{ margin: 'auto' }}
            page={page}
            shape="rounded"
          />
        )}
        <Fab
          size="medium"
          color="primary"
          onClick={() => {
            history.push({
              pathname: `/patient-evolution/${idActual}`
            });
          }}
        >
          <Add />
        </Fab>
      </Box>
    );
  };

  return (
    <Box
      ref={containerRef}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative',
        overflow: 'hidden'
      }}>
      <Box
        sx={{
          padding: '12px !important',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflow: 'hidden'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <TextField
            fullWidth
            placeholder={t("search")}
            onChange={handleSearchChange}
            value={searchValue}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            startIcon={<TuneIcon />}
            onClick={handleShowSidebar}
          >
            {t("filters")}
          </Button>
        </Box>

        <Box sx={{
          flex: 1,
          overflowY: 'auto'
        }}>
          {renderContent()}
        </Box>
      </Box>

      {renderFooter()}

      <Drawer
        anchor="right"
        open={showSidebar}
        onClose={handleCloseSidebar}
        container={containerRef.current}
        variant="temporary"
        ModalProps={{
          keepMounted: true,
          disablePortal: true
        }}
        sx={{
          position: 'absolute',
          '& .MuiDrawer-paper': {
            position: 'absolute',
            height: '100%',
            width: 300
          },
          '& .MuiBackdrop-root': {
            position: 'absolute'
          }
        }}
      >
        <Box sx={{ width: '100%', p: '8px !important' }}>
          <Box sx={{ mb: 3, display: 'flex', alignItems: 'center', gap: 1 }}>
            <TuneIcon />
            <Typography variant="h6">{t("filters")}</Typography>
          </Box>
          <FilterSidebar
            dateFiltered={dateFiltered}
            setDateFiltered={setDateFiltered}
            setSpecialityFilter={setSpecialityFilter}
            handleCloseSidebar={handleCloseSidebar}
            onFilterChange={handleFilterChange}
          />
        </Box>
      </Drawer>
    </Box>
  );
};

export default EvolutionsTab;