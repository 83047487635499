import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { Close } from "@mui/icons-material"
import { useTranslation } from "react-i18next";

const FullScreenModal = ({
  open,
  handleClose,
  title,
  submitButton,
  Content,
  onSubmit,
  canSubmit = true,
  saveDialog = false,
  exitDialog = false,
  validData = true,
}) => {
  const { t } = useTranslation("full_screen_modal");
  const [chosenDialog, setChosenDialog] = useState("save");
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const attemptToClose = () => {
    if (exitDialog) {
      setChosenDialog("exit");
      setConfirmationOpen(true);
      return;
    }
    handleClose(false);
  }

  const attemptToSave = async () => {
    if (saveDialog) {
      setChosenDialog("save");
      setConfirmationOpen(true);
      return;
    }
    await onSubmit();
    handleClose(false);
  }

  const handleConfirmation = async () => {
    setConfirmationOpen(false);
    if (chosenDialog === "save") {
      await onSubmit()
    }
    handleClose(false);
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => attemptToClose()}
      PaperProps={{ sx: { backgroundColor: "modalBackground.main" } }}
    >
      <AppBar sx={{ position: "fixed" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => attemptToClose()}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {title}
          </Typography>
          {canSubmit && <Button
            disabled={!validData}
            autoFocus
            color="inherit"
            onClick={() => attemptToSave()}
          >
            {submitButton}
          </Button>}
        </Toolbar>
      </AppBar>
      <DialogContent sx={{ mt: 4, overflow: "auto" }}>
        {Content}
        <Dialog open={confirmationOpen}>
          <DialogContent>
            <Grid container padding={2}>
              <Typography variant="h5">
                {t(`modal.${chosenDialog}.title`)}
              </Typography>
            </Grid>
            <Grid container padding={2}>
              <Typography variant="body1">
                {t(`modal.${chosenDialog}.secondary`)}
              </Typography>
            </Grid>
            <Grid container padding={2} columnSpacing={1} sx={{ justifyContent: "end" }}>
              <Grid>
                <Button variant="outlined" onClick={() => setConfirmationOpen(false)}>
                  {t(`modal.${chosenDialog}.continue`)}
                </Button>
              </Grid>
              <Grid>
                <Button variant="contained" onClick={() => handleConfirmation()}>
                  {t(`modal.${chosenDialog}.confirm`)}
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </DialogContent>
    </Dialog>
  )
}

export default FullScreenModal;