import React from "react";
import { useTranslation } from "react-i18next";
import {
  Unstable_Grid2 as Grid,
  List,
  Card,
  CardContent,
  CardActionArea,
  Typography,
  Collapse,
  Box,
  CircularProgress,
  IconButton,
  Divider,
  Rating,

} from "@mui/material";

import {
  Favorite,
  FavoriteBorder,
  EditOutlined
} from "@mui/icons-material";

const PatientList = ({
  patients,
  selectedPatient,
  updateSelectedPatient,
  loading,
  editPatient,
}) => {
  const { t } = useTranslation(["patient_evaluation", "registers"]);

  return <>
    {loading ? <Grid
      container
      direction="column"
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid>
        <CircularProgress />
      </Grid>
      <Grid>
        {t("patient.loadingPatients")}
      </Grid>
    </Grid> : <List sx={{ width: '100%' }}>
      {patients.map((patient, index) => (
        <Card
          key={patient._id}
          sx={{
            m: 1,
            border: selectedPatient === index ? '1px solid #6d59c9' : '1px solid #e0e0e0',
            borderRadius: '4px',
            transition: 'all 0.3s'
          }}
        >
          <CardActionArea onClick={() => { updateSelectedPatient(index) }}>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>
                  <Typography variant="h7" color="#60578a">
                    {`${patient.name} ${patient.lastname}`}
                  </Typography>
                  <Typography variant="body2" color="#fdcf97">
                    {patient.rut}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </CardActionArea>

          <Collapse in={selectedPatient === index} timeout="auto" unmountOnExit>
            <CardContent>
              <Box sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
              }}>
                <Typography>
                  {t("registers:perception")}
                </Typography>
                <Typography>
                  {t("registers:compliance")}
                </Typography>
                <Divider sx={{ gridColumn: '1 / -1', my: 1 }} />
                <Rating
                  readOnly
                  value={patient.rating}
                  getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
                  precision={0.5}
                  icon={<Favorite />}
                  emptyIcon={<FavoriteBorder />}
                  sx={{
                    '& .MuiRating-iconEmpty': {
                      color: '#FDAD34', // Yellow color for empty icons
                    }
                  }}
                />
                <Typography>
                  {(patient.percent_compliance || '0') + '%'}
                </Typography>
                <Typography mt={1}>
                  {t("registers:phone")}
                </Typography>
                <Typography mt={1}>
                  {t("registers:email_address")}
                </Typography>
                <Divider sx={{ gridColumn: '1 / -1', my: 1 }} />
                <Typography>
                  {patient.phone || '-'}
                </Typography>

                <Typography>
                  {patient.email || '-'}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  cursor: "pointer"
                }}
                onClick={editPatient}
              >
                <Typography>
                  {t("registers:edit_patient")}
                </Typography>
                <IconButton >
                  <EditOutlined />
                </IconButton>
              </Box>
            </CardContent>
          </Collapse>
        </Card>
      ))}
    </List>}
  </>
}

export default PatientList;