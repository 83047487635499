import React, { useState } from "react"
import {
  Unstable_Grid2 as Grid,
} from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

dayjs.extend(utc);


const ResultTemplateDate = ({
  block,
  sectionIndex,
  blockIndex,
  updateParams,
  blockResult = null
}) => {
  const getResultValue = () => {
    return dayjs.utc(blockResult.params[0].value);
  }

  const [date, setDate] = useState(blockResult ? getResultValue() : null);

  const updateDate = (value) => {
    setDate(value);
    const newResults = [{
      _id: block.params[0]._id,
      value: value
    }]
    updateParams(newResults, sectionIndex, blockIndex, !!(value));
  }


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid paddingTop={1}>
        <DatePicker
          disabled={!!blockResult}
          value={date}
          onChange={(v) => updateDate(v)}
          format={block.params[0].value.format}
          maxDate={block.params[0].value.maxDateToday ? dayjs() : undefined}
          slotProps={{
            inputAdornment: {
              position: "start"
            },
            textField: {
              sx: {
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  maxWidth: "163px"
                },
              },
              size: "small"
            }
          }}
        />
      </Grid>
    </LocalizationProvider>
  )
}

export default ResultTemplateDate;