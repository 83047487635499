import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Unstable_Grid2 as Grid,
  Paper,
  Divider,
  TextField,
  IconButton,

  Tooltip
} from "@mui/material";
import {
  DeleteOutlined,
  ContentCopyOutlined,
  FormatAlignLeftOutlined,
} from "@mui/icons-material"

const SELECT_VALUES = (t) => ({
  TEXTCONTEXT: {
    text: t("template.activity.types.text"),
    icon: <FormatAlignLeftOutlined />,
    defaultParams: [],
    template: null,
  },
})

const BlockTemplateContext = ({
  block,
  updateBlock,
  sectionIndex,
  blockIndex,
  removeBlock,
  duplicateBlock,
  canDelete = false,
  readOnly = false,
}) => {
  const { t } = useTranslation("evaluations_new");

  // eslint-disable-next-line no-unused-vars
  const [type, setType] = useState(block?.type || "TEXTCONTEXT");
  const [title, setTitle] = useState(block?.title || "");
  const [titleError, setTitleError] = useState(false);
  const [description, setDescription] = useState(block?.description || "");
  const [params, setParams] = useState(block?.params || SELECT_VALUES(t).TEXTCONTEXT.defaultParams);
  const valid = useMemo(() => {
    if (params.length) {
      return title.length && params.every((param) => param.valid)
    }
    return title.length
  }, [title, params]);

  const selectedValue = useMemo(() => {
    return SELECT_VALUES(t)[type]
  }, [type]);

  useEffect(() => {
    updateBlock({
      type,
      title,
      description,
      required: false,
      media: [],
      params,
      valid,
      sectionIndex,
      blockIndex
    });
  }, [type, title, description, params, valid])

  const updateTitle = (event) => {
    setTitle(event.target.value);
    setTitleError(!event.target.validity.valid);
  }

  return (
    <Grid>
      <Paper
        elevation={3}
        square={false}
      >
        <Grid paddingX={5} paddingY={3}>
          <TextField
            required
            disabled={readOnly}
            variant="standard"
            placeholder={t("template.activity.contextTitle")}
            value={title}
            onChange={(e) => updateTitle(e)}
            fullWidth={true}
            error={titleError}
            helperText={titleError && t("template.activity.validation.empty")}
            sx={{ '& .MuiInputBase-input::placeholder': { opacity: 1 } }}
          />
        </Grid>
        <Grid paddingX={5}>
          <TextField
            disabled={readOnly}
            variant="standard"
            placeholder={t("template.activity.description")}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth={true}
            sx={{ '& .MuiInputBase-input::placeholder': { opacity: 1 } }}
          />
        </Grid>
        <Grid paddingX={5} paddingY={3}>
          {SELECT_VALUES(t)[type].template && (
            <selectedValue.template
              params={params}
              updateParams={setParams}
              readOnly={readOnly}
            />
          )}
        </Grid>
        <Grid paddingX={2}>
          <Divider />
        </Grid>
        <Grid
          container
          padding={2}
          spacing={1}
          sx={{
            justifyContent: "flex-end",
            alignItems: "center"
          }}>
          <Grid>
            <Tooltip title={t("template.activity.duplicate")}>
              <span>
                <IconButton
                  size="large"
                  disabled={readOnly}
                  onClick={() => duplicateBlock(sectionIndex, blockIndex)}
                >
                  <ContentCopyOutlined />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          <Grid>
            <Tooltip title={t("template.activity.delete")}>
              <span>
                <IconButton
                  size="large"
                  disabled={readOnly || !canDelete}
                  onClick={() => removeBlock(sectionIndex, blockIndex)}
                >
                  <DeleteOutlined />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Paper >
    </Grid >
  )
}

export default BlockTemplateContext;