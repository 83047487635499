import React, { useState } from "react"
import { useTranslation } from "react-i18next";
import {
  Unstable_Grid2 as Grid,
  Typography,
  TextField,
  MenuItem
} from "@mui/material";
import {
  TIME_CONVERTION_TABLE,
  VOLUME_CONVERTION_TABLE,
  DISTANCE_CONVERTION_TABLE
} from "../../../lib/units_converter";

const UNITS = {
  DISTANCE: ["m", "cm", "mm"],
  TIME: ["h", "m", "s"],
  VOLUME: ["l", "cl", "ml"],
  OTHER: ["other"]
}

const ResultTemplateData = ({
  block,
  sectionIndex,
  blockIndex,
  updateParams,
  blockResult = null
}) => {
  const findUnit = () => {
    if (blockResult) {
      return blockResult.params[0].detail.originalUnit;
    }
    return block.typeConfig ? block.typeConfig.unit : UNITS[block.params[0].value.type][0]
  }
  // Convert the unit of the value to the unit of the block
  const convertUnit = (value, fromUnit, toUnit) => {
    if ((fromUnit === toUnit) || !fromUnit || !toUnit) {
      return value;
    }
    let conversionTable = null;
    if (block.typeConfig.dimension === "DISTANCE") {
      conversionTable = DISTANCE_CONVERTION_TABLE;
    } else if (block.typeConfig.dimension === "VOLUME") {
      conversionTable = VOLUME_CONVERTION_TABLE;
    } else if (block.typeConfig.dimension === "TIME") {
      conversionTable = TIME_CONVERTION_TABLE;
    }
    if (!conversionTable) {
      return value;
    }
    return conversionTable[fromUnit][toUnit](value);
  }

  const { t } = useTranslation("patient_evaluation");
  const [unit, setUnit] = useState(findUnit());
  const [answers, setAnswers] = useState(
    Array.from({ length: block.params.length }, (_, index) => {
      if (blockResult && block.typeConfig?.dimension !== "OTHER") {
        return convertUnit(
          blockResult.params[index].value,
          blockResult.params[index].detail.unit,
          blockResult.params[index].detail.originalUnit
        );
      }
      return ""
    })
  )
  const [answerErrors, setAnswerErrors] = useState(Array(block.params.length).fill(false));
  const [initialAnswer, setInitialAnswer] = useState(Array(block.params.length).fill(true));
  const [valid, setValid] = useState(false);

  const checkValidity = (data) => {
    const isValid = data.every((answer, index) => {
      return !block.params[index].required || (answer.length)
    })
    setValid(isValid);
    return isValid;
  }

  const SELECT_VALUES = {
    DISTANCE: UNITS.DISTANCE.map((item) => ({
      text: t(`evaluation.activities.data.options.distance.${item}`),
      value: item
    })),
    TIME: UNITS.TIME.map((item) => ({
      text: t(`evaluation.activities.data.options.time.${item}`),
      value: item
    })),
    VOLUME: UNITS.VOLUME.map((item) => ({
      text: t(`evaluation.activities.data.options.volume.${item}`),
      value: item
    })),
    OTHER: [{
      text: t("evaluation.activities.data.options.other.other"),
      value: "other"
    }]
  }

  const isParamValid = (param, index) => {
    return !answerErrors[index] && (!param.required || (initialAnswer[index] || answers[index]));
  }

  const updateUnit = (value) => {
    setUnit(value);
    const newResults = block?.params.map((param, index) => {
      return {
        _id: param._id,
        value: answers[index],
        detail: {
          unit: value
        }
      }
    });
    updateParams(newResults, sectionIndex, blockIndex, valid);
  };

  // In order to support negative numbers to be typed digit by digit, instead of
  // restricting input to only valid numbers, we allow everything then run a
  // separate check to ensure the number's valid
  const updateValue = (event, index) => {
    const answersCopy = [...answers];
    const answerErrorsCopy = [...answerErrors];
    const initialAnswerCopy = [...initialAnswer];
    // Converts "," to ".", removes all the periods after the first, then removes every character but numbers and [-.]
    const parsed = event.target.value.replace(/,/g, ".")
      .replace(/(?<=\..*?)\./g, '')
      .replace(/[^0-9.-]/g, "");
    // Number is valid if: only numeric digits, optional [-] at the start, optional [.] not at start/end
    const validNumber = /^-?\d+(\.\d+)?$/.test(parsed);
    answersCopy[index] = parsed;
    const newResults = block?.params.map((param, idx) => {
      return {
        _id: param._id,
        value: answersCopy[idx],
        detail: {
          unit,
        }
      }
    });
    // If the answer's empty, there's technically no error, so we set as false
    // This is so no error is triggered for optional questions when empty
    answerErrorsCopy[index] = parsed !== "" && !validNumber;
    initialAnswerCopy[index] = false;
    setAnswers(answersCopy);
    setAnswerErrors(answerErrorsCopy);
    setInitialAnswer(initialAnswerCopy);
    updateParams(newResults, sectionIndex, blockIndex, checkValidity(answersCopy));
  }

  return (
    <Grid container direction="column" rowSpacing={2} paddingTop={2}>
      <Grid>
        <TextField
          disabled={!!blockResult || block.typeConfig?.dimension === "OTHER"}
          select
          variant="outlined"
          value={unit}
          onChange={(e) => updateUnit(e.target.value)}
        >
          {SELECT_VALUES[block.typeConfig ? block.typeConfig.dimension : block.params[0].value.type].map((item, idx) => {
            return (
              <MenuItem
                key={idx}
                value={item.value}
              >
                {item.text}
              </MenuItem>
            )
          })}
        </TextField>
      </Grid>
      <Grid>
        {block.params.map((param, pidx) => (
          <Grid key={pidx} container rowSpacing={2} sx={{ alignItems: "center" }}>
            <Grid>
              <Typography variant="body1" color="gray">
                {pidx + 1}
              </Typography>
            </Grid>
            <Grid>
              <TextField
                required={param.required}
                disabled={!!blockResult}
                error={!isParamValid(param, pidx)}
                variant="standard"
                value={answers[pidx]}
                placeholder={
                  t(`evaluation.activities.data.placeholders.${(block.typeConfig ? block.typeConfig.dimension : block.params[0].value.type).toLowerCase()}`)
                }
                onChange={(event) => updateValue(event, pidx)}
                helperText={(!isParamValid(param, pidx)) && t("evaluation.validation.data")}
              />
            </Grid>
            <Grid>
              <Typography variant="body1" color="gray">
                {t(`evaluation.activities.data.options.${((block.typeConfig ? block.typeConfig.dimension : block.params[0].value.type)).toLowerCase()}.${unit}`)
                  .toLowerCase()}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="subtitle" color="red">
                {param.required ? "*" : ""}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default ResultTemplateData;