import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
} from '@mui/material';
import { ChromePicker } from 'react-color';
import { useTranslation } from 'react-i18next';

const LegendItemEditor = ({ open, onClose, item, onSave }) => {
  const { t } = useTranslation("patient_evaluation");
  const [name, setName] = useState(item?.label || '');
  const [color, setColor] = useState(item?.color || '#1976d2');

  const handleSave = () => {
    onSave({
      ...item,
      label: name,
      color: color,
    });
    onClose();
  };

  const handleClose = () => {
    setName(item?.label || '');
    setColor(item?.color || '#1976d2');
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("progress.legend_item_editor.title")}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1, minWidth: 300 }}>
          <TextField
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
          />
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <ChromePicker
              color={color}
              onChange={(color) => setColor(color.hex)}
              disableAlpha
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>{t("progress.cancel")}</Button>
        <Button variant="contained" onClick={handleSave}>
          {t("progress.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LegendItemEditor; 