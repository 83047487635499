import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Unstable_Grid2 as Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  CircularProgress,
  Typography
} from "@mui/material";
import {
  ArrowForward,
} from "@mui/icons-material"
import FullScreenModal from "../../../../components/FullScreenModal";
import EvaluationResultModal from "../../components/EvaluationResultModal";

const ResultList = ({ results, loading, patient }) => {
  const { t } = useTranslation("patient_evaluation");
  const [selectedResult, setSelectedResult] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const tableHeaders = [
    t("history.results.name"),
    t("history.results.tags"),
    t("history.results.evaluator"),
    t("history.results.date"),
    "",
  ]

  const formatDate = (date) => {
    if (!date) {
      return null;
    }
    return date.split("T")[0]
  }

  const handleViewResult = (evaluation) => {
    setSelectedResult(evaluation);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedResult(null);
  };

  return (
    <>
      {loading ? <Grid
        container
        direction="column"
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid>
          <CircularProgress />
        </Grid>
        <Grid>
          {t("patient.loadingResults")}
        </Grid>
      </Grid> : <TableContainer sx={{ maxHeight: "70vh" }}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, index) => (
                <TableCell
                  key={index}
                  sx={{ textAlign: "left !important", position: "sticky", top: 0, zIndex: 2 }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map((evaluation, idx) => (
              <TableRow
                key={evaluation._id}
              >
                <TableCell sx={{ textAlign: "left !important" }}>
                  {evaluation.evaluationTemplate.title || "N/A"}
                </TableCell>
                <TableCell sx={{ textAlign: "left !important" }}>
                  {/* TODO: Add tags */}
                </TableCell>
                <TableCell sx={{ textAlign: "left !important" }}>
                  {evaluation.therapist.name + " " + evaluation.therapist.lastname}
                </TableCell>
                <TableCell sx={{ textAlign: "left !important" }}>
                  {formatDate(evaluation.evaluatedAt || evaluation.createdAt)}
                </TableCell>
                <TableCell>
                  <Grid
                    container
                    sx={{ justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                    onClick={() => handleViewResult(evaluation)}
                  >
                    <Grid>
                      <Typography
                        variant="body2"
                        sx={{ textDecoration: 'underline' }}
                      >
                        {t("history.results.view")}
                      </Typography>
                    </Grid>
                    <Grid>
                      <IconButton>
                        <ArrowForward />
                      </IconButton>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>}

      <FullScreenModal
        open={dialogOpen}
        handleClose={handleCloseDialog}
        title={t("evaluation.view")}
        Content={
          <EvaluationResultModal
            patient={patient}
            patientResult={selectedResult || null}
            readOnly
          />
        }
      />
    </>
  )
}

export default ResultList;