import axios from 'axios';

export const evaluationTemplateQueries = {
  getEvaluationTemplates: async ({ page }) => {
    const response = await axios.get('/evaluation_template', { params: { page } });
    return {
      templates: response.data.templates,
      pages: response.data.pages
    };
  }
}