import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tabs, Tab, Typography, Box, Paper } from "@material-ui/core";
import PatientsData from "../pages/ClinicalRecordsPage/components/viewsPatients/patientsData";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { getUserId } from "../lib/router";
import UploadFiles from "../components/uploadFiles/static";
import { connect } from "../store";
import { useHistory } from "react-router-dom";
import Editor from "../components/editor";
import API from "../services/api";

import "../assets/css/viewsPatients/medicalVisitStyle.css";


const api = new API();

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const styles = makeStyles({
  root: {
    zIndex: 0,
    maxWidth: "none",
    color: "#281B65",
    boxShadow: "none",
    height: "52px",
    borderBottom: "4px solid #E7ECF4",
    "&:focus": {
      outline: "none",
    },
  },
  label: {
    textTransform: "capitalize",
  },
});

const DisabledTabs = (props) => {
  const { t } = useTranslation(["patient_file","evaluations"]);
  const classes = styles();

  const [value, setValue] = React.useState(0);

  const history = useHistory();
  const [pacient_id, setPacient_id] = useState("");
  const [files, setFiles] = useState([]);
  const [therapist, setTherapist] = useState("");
  const [therapistId, setTherapistId] = useState("");
  const [editor, setEditor] = useState("");

  const [postLoading, setPostLoading] = useState(false)

  useEffect(() => {
    setPacient_id(props.id);
    setTherapist(getUserId().name);
    setTherapistId(getUserId().userId);
    if (props.event) {
      getInfoEventById(props.event);
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const createRecord = () => {
    if(postLoading) return;
    if (editor === "") {
      props.notify({ type: "error", title: "Error", text: t("notify.description_req") });
      setPostLoading(false)
      return;
    }

    const form = new FormData();
    form.append("pacient_id", pacient_id);
    form.append("comment", editor);
    form.append("mode", "visit");
    form.append("therapist", therapist);
    form.append("therapistId", therapistId);
    // TODO: Remove evaluations when the backend is ready
    form.append("evaluations", JSON.stringify([]));

    if (props.event) {
      form.append("event", props.event);
    }

    files.map((e) => form.append("files", e, e.name));

    setPostLoading(true);
    api
      .createRecord(form)
      .then((res) => {
        if (res.success) {
          if (props.event) {
            api
              .changeStatusEvent(props.event, { status: 2 })
              .then(() => {
                props.notify({ type: "success", title: t('notify.success'), text: t("notify.successful") });
                setPostLoading(false);
                history.push(`/clinical-records/${pacient_id}`);
              })
              .catch(() => props.notify({ type: "error", title: t('notify.error'), text: t('notify.errorText') }));
          } else {
            props.notify({ type: "success", title: t('notify.success'), text: t("notify.successful") });
            history.push('/clinical_records/patient_evaluations');
          }
        }
      })
      .catch(() => props.notify({ type: "error", title: t('notify.error'), text: t('notify.errorText') }));
  };

  const getInfoEventById = () => {};


  return (
    <Paper square>
      <Tabs value={value} onChange={handleChange} aria-label="disabled tabs example">
        <Tab className={classes.root} label={t("patient_file")} {...a11yProps(0)} />
        <Tab className={classes.root} label={t("patient_data")} {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <div className="bg-white mt-4 mb-3">
          <Grid container direction="row" justifyContent="center">
            <Grid item xs={12} md={6} className="pl-4">
              <Grid container direction="row">
                <Grid item xs={12} className="mb-3">
                  <Editor value={editor} onChange={setEditor} />
                </Grid>
                <UploadFiles onChange={setFiles} accept="image/png, image/jpeg,.pdf" formats={[ "application/pdf", "image/png", "image/jpeg" ]}/>
              </Grid>
            </Grid>

            <Grid
              className="p-4"
              container
              direction="column"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <button
                className="btn-white-tf"
                name={t("save")}
                onClick={createRecord}
                variant="contained"
                color="primary"
              >
                {t("save")}
              </button>
            </Grid>
          </Grid>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PatientsData id={props.id} />
      </TabPanel>
    </Paper>
  );
};

export default connect(DisabledTabs);
