import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { evaluationKeys } from './keys';
import { evaluationQueries } from './queries';

export const usePatientEvaluations = (patientId, page) => {
  return useQuery(
    evaluationKeys.byPatientPaginated(patientId, page),
    () => evaluationQueries.getPatientEvaluations({ patientId, page }),
    {
      enabled: !!patientId,
    }
  );
};

export const useCreateEvaluation = () => {
  const queryClient = useQueryClient();
  
  return useMutation(
    evaluationQueries.createEvaluation,
    {
      onSuccess: (_, variables) => {
        // Invalidate all evaluation queries for this patient
        queryClient.invalidateQueries(
          ['evaluation_results', 'patient', variables.patientId]
        );
      },
    }
  );
}
